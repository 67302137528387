'use client';

import type { Block_Video } from '@/types/fragments/mainNeo';
import { getSpacingClasses } from '@/lib/utils';
import Script from 'next/script';
import Picture from '@/components/Picture';
import { getImageSources } from '@/lib/craftImage';
import PlayButton from '@/components/icons/PlayButton.svg';
import { useState } from 'react';

export default function VideoBlock({
  heading,
  headingElement,
  vimeoId,
  fallbackImage: fallbackImages,
  anchor,
  spacing,
  ...rawImageSources
}: Block_Video) {
  const [showVideo, setShowVideo] = useState(false);
  const [iframeLoaded, setIframeLoaded] = useState(false);

  const fallbackImage = fallbackImages[0];
  if (!vimeoId || !fallbackImage) return null;

  const spacingClasses = getSpacingClasses(spacing);
  const Heading = headingElement as keyof React.JSX.IntrinsicElements;

  const onHandleClick = () => setShowVideo(true);
  const onIframeLoad = () => setIframeLoaded(true);

  return (
    <section id={anchor || undefined} className={`container max-w-rich-text ${spacingClasses}`}>
      {heading && (
        <Heading className="heading mb-7 text-center text-4xl lg:text-5xl">{heading}</Heading>
      )}
      <div className="relative aspect-video">
        {showVideo === false ? (
          <>
            <div
              className="absolute inset-0 grid cursor-pointer place-items-center"
              onClick={onHandleClick}
            >
              <PlayButton className="max-w-[15%]" />
            </div>
            <Picture
              sources={getImageSources('image', rawImageSources)}
              imageProps={{
                src: fallbackImage.url,
                alt: fallbackImage.alt || fallbackImage.title,
                className: 'aspect-video h-full w-full object-cover',
                width: fallbackImage.width,
                height: fallbackImage.height,
              }}
            />
          </>
        ) : (
          <>
            {!iframeLoaded && (
              <div className="absolute inset-0 grid place-items-center">
                <div
                  className="inline-block size-8 animate-spin rounded-full border-[3px] border-current border-t-transparent text-brabners-copper"
                  role="status"
                  aria-label="loading"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
            <iframe
              src={`https://player.vimeo.com/video/${vimeoId}?dnt=1&badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1`}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              height="100%"
              width="100%"
              loading="lazy"
              style={{ position: 'absolute', top: 0, left: 0 }}
              onLoad={onIframeLoad}
            />
          </>
        )}
      </div>
      {/*
        Only sets and __cf_bm _cfuvid which are used for Cloudflare rate limiting and identifying bots.
        Cookiebot automatically flags these as necessary when using auto mode.
      */}
      <Script src="https://player.vimeo.com/api/player.js" data-cookieconsent="necessary" />
    </section>
  );
}
