/**
 * Link component - a wrapper around NextLink and <a> tag to handle internal and external links, as
 * the Next.js Link component does not support external links.
 */

import { isUrlExternal } from '@/lib/utils';
import NextLink from 'next/link';

type LinkProps = React.ComponentPropsWithoutRef<'a'> & {
  children?: React.ReactNode;
};

export default function Link({ children, href, ...attributes }: LinkProps) {
  // Craft element
  if (href && !isUrlExternal(href)) {
    return (
      /* @ts-expect-error caused by exactOptionalPropertyTypes: true */
      <NextLink href={href} {...attributes}>
        {children}
      </NextLink>
    );
  }

  // External URL - new tab
  if (attributes.target === '_blank') {
    return (
      <a
        href={href}
        rel="nofollow noopener"
        {...attributes} // This includes target="_blank"
      >
        {children}
      </a>
    );
  }
  // External URL - same tab
  return (
    <a href={href} rel="nofollow" {...attributes}>
      {children}
    </a>
  );
}
