'use client';

import type { ResponseBody } from '@/types/api';
import type { Form as FormCmsData } from '@/types/fragments/form';
import type { AlertState } from './FormAlert';
import { useState, useRef, useEffect } from 'react';
import { useFormState } from 'react-dom';
import Pristine from 'pristinejs';
import { resetValidation, runValidation, applyServerValidation } from './validation';
import FormAlert from './FormAlert';
import FormPage from './FormPage';

type FormBodyProps = {
  form: FormCmsData;
  saveSubmission: (prevState: ResponseBody | null, formData: FormData) => Promise<ResponseBody>;
};
type FormBody = (props: FormBodyProps) => React.JSX.Element | null;

const FormBody: FormBody = ({ form, saveSubmission }) => {
  // ============================== State
  const [alertState, setAlertState] = useState<AlertState>(null);
  const [activePage, setActivePage] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [submissionState, submissionAction] = useFormState(saveSubmission, null);

  // ============================== Refs
  const formRef = useRef<HTMLFormElement | null>(null);
  const activePageRef = useRef<HTMLDivElement | null>(null);
  const validatorRef = useRef<InstanceType<typeof Pristine> | null>(null);

  // ============================== Derived state
  const stepPercentage = (activePage / form.pages.length) * 100;
  const alertMessageHtml =
    alertState === 'success'
      ? form.settings.submitActionMessageHtml
      : form.settings.errorMessageHtml;

  // ============================== Event handlers
  const onTabClick = (index: number) => {
    setActivePage(index);
  };

  const onSubmit = async (event: React.FormEvent) => {
    setLoading(true);
    setAlertState(null);

    // Always validate the current page only, not the entire form
    const $form = formRef.current!;
    const $page = activePageRef.current!;

    // Setup a validator
    if (!validatorRef.current) {
      validatorRef.current = new Pristine($form);
    }

    // Clear out validation errors
    resetValidation($form);

    const isLastPage = activePage === form.pages.length - 1;
    const valid = runValidation(validatorRef.current, $page);

    // Validate the form - for each page
    if (!valid) {
      setLoading(false);
      setAlertState('error');
      event.preventDefault();

      // Scroll to the first error
      const $firstError = $form.querySelector('.form-error-message');
      ($firstError ?? $form).scrollIntoView();

      return;
    }

    // Are we on the last page, or on a multi-step page?
    if (!isLastPage) {
      setLoading(false);
      setActivePage(activePage + 1);
      event.preventDefault();
      return;
    }

    // Allow form to submit
  };

  // ============================== Effects
  // Handle form submission response
  useEffect(() => {
    if (!submissionState) return;
    setLoading(false);
    const $form = formRef.current!;

    if (submissionState.status === 'success') {
      if (form.settings.submitAction === 'message') {
        setAlertState('success');
      }
      $form.reset();
    } else if (submissionState.status === 'error') {
      setAlertState('error');

      // Apply server-side errors
      applyServerValidation(validatorRef.current!, $form, submissionState.errors);
    }
  }, [submissionState, form]);

  return (
    <form ref={formRef} onSubmit={onSubmit} action={submissionAction} noValidate>
      <FormAlert
        messageHtml={alertMessageHtml}
        alertState={alertState}
        setAlertState={setAlertState}
      />

      {/* {form.settings.displayFormTitle && (
        <h2 className="heading text-3xl mb-7 text-center text-brabners-copper-300">{form.title}</h2>
      )} */}

      {form.settings.displayPageTabs && (
        <div className="border-b border-gray-200 px-4 sm:px-6">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {form.pages.map((page, index) => (
              <a
                key={index}
                href="#"
                className={`whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium ${
                  index === activePage
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  onTabClick(index);
                }}
              >
                {page.name}
              </a>
            ))}
          </nav>
        </div>
      )}

      {form.settings.displayPageProgress && (
        <div className="mt-4 px-4 sm:px-6">
          <div className="flex h-5 overflow-hidden rounded-md bg-gray-200 text-xs font-medium text-white">
            <div
              className="flex flex-col justify-center bg-indigo-500 text-center"
              style={{ width: `${stepPercentage}%` }}
              role="progressbar"
              aria-valuenow={stepPercentage}
              aria-valuemin={0}
              aria-valuemax={100}
            >
              <span>{stepPercentage}%</span>
            </div>
          </div>
        </div>
      )}

      {form.pages.map((page, pageNum) => (
        <FormPage
          ref={pageNum === activePage ? activePageRef : undefined}
          key={pageNum}
          activePage={activePage}
          setActivePage={setActivePage}
          page={page}
          pageIndex={pageNum}
          form={form}
          loading={loading}
        />
      ))}
    </form>
  );
};

export default FormBody;
