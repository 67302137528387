'use client';

import type { Block_Accreditations } from '@/types/fragments/mainNeo';
import { getImageSources } from '@/lib/craftImage';
import { tailwindScreensUnitless } from '@/lib/tailwindConfig';
import { getSpacingClasses } from '@/lib/utils';
import SwiperCarousel from '@/components/Carousel';
import Picture from '@/components/Picture';

/**
 * Swiper uses a shadow dom so for anything other than CSS variables we need to use `injectStyles`.
 */
const swiperStyles = `.swiper-wrapper {
  align-items: center;
}
`;

export default function AccreditationsBlock({
  heading,
  headingElement,
  accreditations,
  showLabels,
  enableCarousel,
  anchor,
  spacing,
}: Block_Accreditations) {
  const Heading = headingElement as keyof React.JSX.IntrinsicElements;
  const spacingClasses = getSpacingClasses(spacing);

  return (
    <section
      id={anchor || undefined}
      className={`${enableCarousel ? 'bg-brabners-cream-400' : 'bg-brabners-cream'} ${spacingClasses}`}
    >
      <div className="container">
        {heading && (
          <Heading className="heading mb-7 text-center text-4xl lg:text-5xl">{heading}</Heading>
        )}
        {enableCarousel ? (
          <SwiperCarousel
            paramsAsProps={{
              injectStyles: [swiperStyles],
              breakpoints: {
                [tailwindScreensUnitless.md]: {
                  slidesPerView: 3,
                  centeredSlides: false,
                },
                [tailwindScreensUnitless.lg]: {
                  slidesPerView: 5,
                  centeredSlides: false,
                },
              },
            }}
            navigation={true}
            class={`${accreditations.length > 6 ? '[--swiper-pagination-bottom:-0.5rem]' : '[--swiper-pagination-bottom:0.5rem]'} [--swiper-navigation-sides-offset:0] [--swiper-pagination-bullet-inactive-color:theme(colors.brabners.cream.600)] [--swiper-theme-color:theme(colors.brabners.copper.300)] xs:[--swiper-pagination-bottom:1.25rem]`}
          >
            {accreditations.map(({ id, fallbackImage: fallbackImages, ...accreditation }) => {
              const fallbackImage = fallbackImages[0];
              if (!fallbackImage) return null;
              return (
                <swiper-slide key={id} suppressHydrationWarning>
                  <Accreditation
                    className="p-5 pb-16 xl:px-0"
                    id={id}
                    fallbackImage={fallbackImage}
                    showLabels={showLabels}
                    enableCarousel={true}
                    {...accreditation}
                  />
                </swiper-slide>
              );
            })}
          </SwiperCarousel>
        ) : (
          <div className="mx-auto max-w-[60rem]">
            <div className="flex flex-wrap justify-center gap-10 py-6 md:py-12">
              {accreditations.map(({ id, fallbackImage: fallbackImages, ...accreditation }) => {
                const fallbackImage = fallbackImages[0];
                if (!fallbackImage) return null;
                return (
                  <Accreditation
                    key={id}
                    className="flex flex-col items-center justify-center"
                    id={id}
                    fallbackImage={fallbackImage}
                    showLabels={showLabels}
                    enableCarousel={false}
                    {...accreditation}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

type Block_Accreditations_Accreditation = Block_Accreditations['accreditations'][number];
type AccreditationProps = Omit<Block_Accreditations_Accreditation, 'fallbackImage'> & {
  showLabels: Block_Accreditations['showLabels'];
  fallbackImage: Block_Accreditations_Accreditation['fallbackImage'][number];
  enableCarousel: Block_Accreditations['enableCarousel'];
  className: string;
  slug: string;
};

function Accreditation({
  id,
  fallbackImage,
  showLabels,
  title,
  className,
  enableCarousel,
  slug,
  ...rawImageSources
}: AccreditationProps) {
  return (
    <div className={className}>
      {slug === 'sra' ? (
        <div style={{ maxWidth: '275px', maxHeight: '163px' }}>
          <div
            style={{
              position: 'relative',
              paddingBottom: '59.1%',
              height: 'auto',
              overflow: 'hidden',
            }}
          >
            <iframe
              src="https://cdn.yoshki.com/iframe/55845r.html"
              style={{
                border: 0,
                margin: 0,
                padding: 0,
                backgroundColor: 'transparent',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                position: 'relative',
              }}
              scrolling="no"
            />
          </div>
        </div>
      ) : (
        <Picture
          sources={getImageSources('image', rawImageSources)}
          imageProps={{
            src: fallbackImage.url,
            alt: fallbackImage.alt || fallbackImage.title,
            className: enableCarousel
              ? 'w-full h-full max-w-[150px] mx-auto object-contain'
              : 'object-contain',
            width: fallbackImage.width,
            height: fallbackImage.height,
          }}
        />
      )}
      {showLabels && (
        <p className="mx-auto max-w-sm pt-2 text-center text-xl text-brabners-beige">{title}</p>
      )}
    </div>
  );
}
