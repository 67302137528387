import type { MutableRefObject } from 'react';
import { useRouter } from 'next/navigation';

type InsightsPaginationProps = {
  currentPage: number;
  numPages: number;
  headingRef: MutableRefObject<HTMLDivElement | null>;
};

export default function InsightsPagination({
  currentPage,
  numPages,
  headingRef,
}: InsightsPaginationProps) {
  // Previous page buttons
  const navItems = [
    <NavItem
      key="prev"
      url={`?p=${currentPage - 1}`}
      ariaLabel="Previous Page"
      isDisabled={currentPage < 2}
      headingRef={headingRef}
    >
      ‹
    </NavItem>,
  ];

  // Page buttons
  for (let i = 1; i <= numPages; i++) {
    navItems.push(
      <NavItem
        key={i}
        url={`?p=${i}`}
        ariaLabel={`Page ${i}`}
        isSelected={i === currentPage}
        headingRef={headingRef}
      >
        {i}
      </NavItem>,
    );
  }

  // Next page buttons
  navItems.push(
    <NavItem
      key="next"
      url={`?p=${currentPage + 1}`}
      ariaLabel="Next Page"
      isDisabled={currentPage === numPages}
      headingRef={headingRef}
    >
      ›
    </NavItem>,
  );

  return (
    <ul className="mb-10 flex items-center justify-center gap-1 text-lg xs:gap-2 xs:text-xl">
      {navItems}
    </ul>
  );
}

type NavItemProps = {
  children: React.ReactNode;
  url: string;
  ariaLabel: string;
  isSelected?: boolean;
  isDisabled?: boolean;
} & Pick<InsightsPaginationProps, 'headingRef'>;

function NavItem({
  children,
  url,
  ariaLabel,
  isSelected = false,
  isDisabled = false,
  headingRef,
}: NavItemProps) {
  const router = useRouter();

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    router.push(url, { scroll: false });
    headingRef.current?.scrollIntoView();
  };

  return (
    <li
      className={`group h-7 w-7 rounded-full text-center transition-colors duration-300 ${isSelected ? 'bg-brabners-copper text-white' : ''} ${isDisabled ? 'disabled text-murg-grey cursor-not-allowed opacity-60' : ''}`}
    >
      {isDisabled ? (
        <span aria-label={ariaLabel}>{children}</span>
      ) : (
        <a href={url} aria-label={ariaLabel} onClick={handleClick}>
          {children}
        </a>
      )}
    </li>
  );
}
