'use client';

import type { Block_Testimonials } from '@/types/fragments/mainNeo';
import getColourScheme, { type Colourway } from '@/lib/theme';
import SwiperCarousel from '@/components/Carousel';
import WaveVerticalGraphicRight from '@/components/icons/WaveVerticalGraphicRight.svg';

/**
 * Swiper uses a shadow dom so for anything other than CSS variables we need to use `injectStyles`.
 */
const swiperStyles = `@media (max-width: 1023x) {
  .swiper-button-prev,
  .swiper-button-next {
    top: auto;
    bottom: var(--swiper-pagination-bottom, 8px);
    z-index: 20;
  }
}`;

export default function TestimonialsBlock({
  testimonialsColourway,
  showIcon,
  testimonials,
  anchor,
}: Block_Testimonials) {
  const theme = getColourScheme(testimonialsColourway as Colourway);
  const iconTheme = getColourScheme('blue');

  return (
    <section
      id={anchor || undefined}
      // Use cream by default for both Brabners person and default theme, for alt style use pink for
      // Brabners personal and blue for default theme.
      className={`${showIcon ? 'md:grid md:grid-cols-1-2' : ''} ${theme.bg} ${theme.text} ${theme.heading}`}
    >
      {showIcon && (
        <div
          className={`relative grid min-h-80 place-items-center overflow-hidden text-brabners-cream md:min-h-[auto] ${iconTheme.bg}`}
        >
          <WaveVerticalGraphicRight
            height="100%"
            className={`absolute left-1/2 top-0 -translate-x-1/2 ${iconTheme.pattern}`}
          />
          {/* Brabners B */}
          <svg
            width="100%"
            viewBox="0 0 183 192"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            role="img"
            aria-label="Brabners B logo"
            // relative to position infront of wave
            className="relative -left-1 block max-h-[36%] max-w-[min(36%,11.5rem)]"
          >
            <path
              d="M142.253 86.9848C162.388 81.3749 176.336 65.5388 176.336 47.2185C176.336 31.0512 169.963 16.954 155.394 9.48097C138.404 0.745229 111.336 0 86.0677 0C39.133 0 0 4.61628 0 4.61628V11.6132L1.63485 11.5718C4.0354 11.5718 15.9553 12.1721 16.0174 23.8473V169.726C16.0174 181.484 0 182.043 0 182.043V189.04C0 189.04 75.3481 192 83.3361 192C111.087 192 131.74 191.027 146.475 186.535C176.254 177.468 183 158.692 183 137.371C183 114.455 164.975 92.9052 142.232 86.9848M90.6205 83.859H73.7753V11.4268C73.7753 11.4268 73.8374 9.77078 82.3221 9.77078C105.603 9.77078 116.302 27.7598 116.302 47.8189C116.302 67.8779 105.024 83.859 90.6205 83.859ZM92.0484 90.8351C106.886 90.8351 119.427 112.467 119.427 138.095C119.427 163.723 109.245 182.685 83.2947 182.685C76.7346 182.685 73.7753 181.298 73.7753 181.298V90.8558H92.0484V90.8351Z"
              fill="currentColor"
            />
          </svg>
        </div>
      )}
      {/* Using wrapper as swiper-container doesn't seem to work as a grid item */}
      <div>
        {testimonials.length > 1 ? (
          <SwiperCarousel
            paramsAsProps={{
              injectStyles: [swiperStyles],
            }}
            navigation={true}
            class={`${theme.swiperTheme} ${theme.swiperPaginationInactive} [--swiper-navigation-sides-offset:20%] [--swiper-pagination-bottom:3.125rem] md:[--swiper-navigation-sides-offset:6%] md:[--swiper-pagination-bottom:2.5rem]`}
          >
            {testimonials.map((testimonial) => (
              <swiper-slide
                class="px-10 pb-32 pt-10 md:px-24 md:py-34"
                key={testimonial.id}
                suppressHydrationWarning
              >
                <Testimonial {...testimonial} />
              </swiper-slide>
            ))}
          </SwiperCarousel>
        ) : (
          testimonials.map((testimonial) => (
            <div className="px-10 pb-12 pt-10 md:px-24 md:py-32" key={testimonial.id}>
              <Testimonial {...testimonial} />
            </div>
          ))
        )}
      </div>
    </section>
  );
}

function Testimonial({ quote, author }: Block_Testimonials['testimonials'][number]) {
  return (
    <div className="mx-auto flex max-w-2xl flex-col items-center text-center">
      {/* Quote symbol */}
      <svg
        width="60"
        height="44"
        viewBox="0 0 60 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="presentation"
        className="mb-8 text-brabners-heading"
      >
        <path
          d="M26.4937 33.24C26.4937 39.6 21.4537 43.68 14.6137 43.68C5.61374 43.68 0.09375 35.52 0.09375 26.88C0.09375 10.32 18.3338 0 20.2538 0C20.7338 0 21.2137 0.239998 21.2137 1.08C21.2137 3.12 8.97375 6.12 8.97375 18.12C8.97375 22.08 11.0138 23.28 15.6938 23.28C22.5338 23.28 26.4937 27 26.4937 33.24ZM59.8538 33.24C59.8538 39.6 54.8138 43.68 47.9738 43.68C38.9738 43.68 33.4538 35.52 33.4538 26.88C33.4538 10.32 51.6937 0 53.6137 0C54.0937 0 54.5738 0.239998 54.5738 1.08C54.5738 3.12 42.3337 6.12 42.3337 18.12C42.3337 22.08 44.3737 23.28 49.0537 23.28C55.8937 23.28 59.8538 27 59.8538 33.24Z"
          fill="currentColor"
        />
      </svg>
      <p className="heading mb-6 text-xl lg:text-2xl">&ldquo;{quote}&rdquo;</p>
      <p>{author}</p>
    </div>
  );
}
