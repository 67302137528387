import type { FormField } from '@/types/fragments/form';
import type { SubField, FieldWithSubfields } from '@/types/forms';
import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';

type FieldLabelProps<F extends FormField = FormField> = {
  id: string;
  field: F;
  position?: Position;
} & (F extends FieldWithSubfields
  ? {
      subField: SubField<F>;
    }
  : {
      subField?: never;
    });

export default function FieldLabel<F extends FormField = FormField>({
  id,
  field: parentField,
  subField,
  position = 'above-input',
}: FieldLabelProps<F>) {
  const field = subField ?? parentField;
  if (!canShow(field, position)) return null;
  return (
    <label htmlFor={id} className={`${position === 'above-input' ? 'mb-2' : 'mt-2'} block`}>
      {field.label}{' '}
      {field.required && <span className="font-bold text-brabners-copper-300">*</span>}
    </label>
  );
}

type Position = 'above-input' | 'below-input';

/**
 * Not using useState here because this doesn't need to re-render. This is similar to v-once in Vue.
 */
function canShow(field: FormField | SubField<FieldWithSubfields>, position: Position): boolean {
  const className = `verbb\\formie\\positions\\${upperFirst(camelCase(position))}`;

  if (field.labelPosition === className) {
    return true;
  } else if (!field.labelPosition && position === 'above-input') {
    return true;
  } else {
    return false;
  }
}
