import type { FieldTypeProps } from '@/types/forms';
import type { FormField_Radio } from '@/types/fragments/form';
import useFieldAttrs from '../fieldAttrs';
import FieldInstructions from './partials/FieldInstructions';

export default function Radio(props: FieldTypeProps<FormField_Radio>) {
  const { field } = props;
  // ============================== Hooks
  const [getId, getName] = useFieldAttrs<FormField_Radio>(props);
  const name = getName();

  return (
    <fieldset>
      <legend className="mb-2 block text-sm">
        {field.name}{' '}
        {field.required && <span className="font-bold text-brabners-copper-300">*</span>}
      </legend>

      <FieldInstructions field={field} position="above-input" />

      <div className="mt-2 space-y-2">
        {field.options.map((option, index) => {
          const id = getId([String(index)]);
          return (
            <div key={index} className="flex items-center">
              <input
                id={id}
                name={name}
                value={option.value}
                required={field.required}
                type="radio"
                className="h-4 w-4"
              />

              <label htmlFor={id} className="ml-3 block text-sm">
                {option.label}
              </label>
            </div>
          );
        })}
      </div>

      <FieldInstructions field={field} position="below-input" />
    </fieldset>
  );
}
