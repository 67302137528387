import type { SearchBoxClassNames } from 'node_modules/react-instantsearch/dist/es/ui/SearchBox';
import SearchIcon from '@/components/icons/SearchIcon.svg';

/**
 * A wrapper to resolve `classNames` passed by the `<SearchBox>` component to `className` for
 * `<SearchIcon>`.
 */
export default function SubmitIcon({ classNames }: { classNames: Partial<SearchBoxClassNames> }) {
  // Hardcode the role to img pending a better solution - https://github.com/gregberge/svgr/issues/961
  return <SearchIcon className={classNames.submitIcon} role="img" />;
}
