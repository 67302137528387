import type { FieldTypeProps } from '@/types/forms';
import type { FormField } from '@/types/fragments/form';
import useFieldAttrs from '../fieldAttrs';
import FieldInstructions from './partials/FieldInstructions';
import FieldLabel from './partials/FieldLabel';

type FieldWrapperProps = { children: React.ReactNode } & FieldTypeProps<FormField>;

/**
 * Wraps the given field with a label and instructions.
 */
export default function FieldWrapper(props: FieldWrapperProps) {
  const { children, field } = props;
  // ============================== Hooks
  const [getId] = useFieldAttrs(props);
  const id = getId();

  return (
    <div>
      <FieldLabel id={id} field={field} position="above-input" />
      <FieldInstructions field={field} position="above-input" />
      {children}
      <FieldInstructions field={field} position="below-input" />
      <FieldLabel id={id} field={field} position="below-input" />
    </div>
  );
}
