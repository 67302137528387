'use client';

import { useEffect, useRef, useState } from 'react';
import HeaderSearchButton from './HeaderSearchButton';
import HeaderSearchDialog from './HeaderSearchDialog';
import useMedia from '@/lib/hooks/useMedia';
import { tailwindScreens } from '@/lib/tailwindConfig';
import '@/styles/overrides/algolia.css';
import type { Colourway } from '@/lib/theme';

type HeaderSearchProps = { className?: string; colourway: Colourway };

export default function HeaderSearch({ className, colourway }: HeaderSearchProps) {
  const isDesktop = useMedia(`(min-width: ${tailwindScreens.md})`);
  const isMobile = isDesktop === false;

  // Always enabled on mobile
  const [isEnabled, setIsEnabled] = useState(isMobile);
  const rootRef = useRef<HTMLDivElement>(null);

  // Set up event listeners to close search when clicking outside (desktop only)
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (!isMobile && rootRef.current && !rootRef.current.contains(event.target as Node)) {
        setIsEnabled(false);
      }
    }

    // Attach the listeners on component mount.
    document.addEventListener('mousedown', handleClickOutside);
    // Detach the listeners on component unmount.
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  // Open / close search if device width/orientation changes
  useEffect(() => {
    if (isMobile && !isEnabled) {
      setIsEnabled(true);
    } else if (!isMobile && isEnabled) {
      setIsEnabled(false);
    }

    // Don't react to isEnabled changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  return (
    <div ref={rootRef} className={`relative ${className || ''}`}>
      <HeaderSearchButton
        className={`${isEnabled ? 'hidden md:invisible md:block' : 'block'}`}
        aria-hidden={isEnabled ? 'true' : undefined}
        setIsEnabled={setIsEnabled}
      />
      {isEnabled && (
        <HeaderSearchDialog
          className="relative z-50 md:absolute md:-right-3 md:-top-header-search-top"
          setIsEnabled={setIsEnabled}
          colourway={colourway}
        />
      )}
    </div>
  );
}
