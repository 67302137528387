import { InstantSearch, SearchBox } from 'react-instantsearch';
import HeaderSearchStats from './HeaderSearchStats';
import { searchClient } from '@/lib/algolia';
import SubmitIcon from '@/components/search/SubmitIcon';
import type { Colourway } from '@/lib/theme';
import getColourScheme from '@/lib/theme';

// Styles (for dev)
// import 'instantsearch.css/themes/reset.css';
// import 'instantsearch.css/themes/satellite.css';

const environment = process.env.NEXT_PUBLIC_ENVIRONMENT as 'dev' | 'staging' | 'production';
const indexName = `${environment}_headerSearch` as const;

type HeaderSearchDialogProps = {
  className?: string;
  setIsEnabled: (value: boolean) => void;
  colourway: Colourway;
};

export default function HeaderSearchDialog({
  className,
  setIsEnabled,
  colourway: headerColourway,
}: HeaderSearchDialogProps) {
  // const { nbHits, query } = useStats();
  const colourway = getColourway(headerColourway);
  const theme = getTheme(colourway);

  function handleEscape(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Escape') {
      setIsEnabled(false);
    }
  }

  return (
    <div
      className={`${className || ''} ${theme.bg} ${theme.text} c-header-search rounded-[1.25rem] focus-within:ring-2 focus-within:ring-brabners-copper md:shadow-lg md:shadow-[rgb(255_255_255_/_.1)]`}
    >
      {/*
        Not using InstantSearchNext as the results are only rendered on the client after a search, not
        on the server.
      */}
      <InstantSearch
        searchClient={searchClient}
        indexName={indexName}
        future={{
          preserveSharedStateOnUnmount: true,
        }}
      >
        <SearchBox
          onKeyDown={handleEscape}
          submitIconComponent={SubmitIcon}
          autoFocus={true}
          placeholder="Search..."
          classNames={{
            root: '',
            form: 'flex relative w-full',
            input: 'bg-transparent flex-1 pr-16 py-1.5 focus:ring-0',
            reset: 'hidden',
            submit: '-translate-y-1/2 absolute right-3 top-1/2',
          }}
        />
        <HeaderSearchStats />
      </InstantSearch>
    </div>
  );
}

function getColourway(headerColourway: Colourway): Colourway {
  switch (headerColourway) {
    case 'white': // Brabners personal
      return 'yellow';
    case 'pink':
      return 'mushroom';
    default:
      return 'navy';
  }
}

function getTheme(colourway: Colourway) {
  switch (colourway) {
    case 'navy':
      return { ...getColourScheme(colourway), bg: 'bg-brabners-navy-200' };
    case 'yellow':
      return { ...getColourScheme(colourway), text: 'text-brabners-navy' };
    default:
      return getColourScheme(colourway);
  }
}
