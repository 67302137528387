import type { ResponseBody } from '@/types/api';
import type Pristine from 'pristinejs';

export const resetValidation = (el: HTMLElement): void => {
  // Clear out all errors for the form
  el.querySelectorAll<HTMLElement>('.form-error-field').forEach((input) => {
    input.classList.remove('form-error-field');
  });

  el.querySelectorAll<HTMLDivElement>('.form-error-message').forEach((input) => {
    input.remove();
  });
};

function showValidation(pristine: Pristine): void {
  const appliedErrors: string[] = [];

  // There's some errors here with Pristine when calling `getErrors()`
  // https://github.com/sha256/Pristine/pull/48
  pristine.fields.forEach((field) => {
    if (!field.errors) {
      field.errors = [];
    }
  });

  pristine.getErrors().forEach(({ input, errors }) => {
    // Add errors to the inputs
    input.classList.add('form-error-field');

    const inputName = input.getAttribute('name') || '';
    const inputType = input.getAttribute('type')?.toLowerCase() || '';

    // Prevent errors applying multiple times
    if (appliedErrors.includes(inputName)) {
      return;
    }

    // Add the error message to the input
    const errorTextElement = document.createElement('div');
    errorTextElement.classList.add('form-error-message');
    errorTextElement.innerHTML = errors.join('<br/>');

    let errorTextParent = input.parentElement!;

    // Handle grouped fields like radio and checkboxes
    if (inputType === 'radio' || inputType === 'checkbox') {
      errorTextParent = errorTextParent.parentElement!;
    }

    errorTextParent.appendChild(errorTextElement);

    // Save so we never have multiple errors per input
    appliedErrors.push(inputName);
  });
}

export const runValidation = (pristine: Pristine, page: HTMLElement): boolean => {
  const inputs = page.querySelectorAll(
    'input:not([type^=hidden]):not([type^=submit]), select, textarea',
  );

  // Validate the inputs
  const valid = pristine.validate(inputs);

  if (!valid) {
    showValidation(pristine);
  }

  return valid;
};

type ResponseErrors = Extract<ResponseBody, { status: 'error' }>['errors'];

export const applyServerValidation = (
  pristine: Pristine,
  el: HTMLElement,
  errors: ResponseErrors,
): void => {
  // Loop through all the errors and apply them to the inputs
  for (const [inputName, inputErrors] of Object.entries(errors)) {
    const input = el.querySelector<HTMLInputElement>(`[name="${inputName}"]`);
    if (input) {
      inputErrors.forEach((inputError) => {
        pristine.addError(input, inputError);
      });
    } else {
      console.warn(`Could not find input with name "${inputName}" to apply server error`);
    }
  }

  showValidation(pristine);
};
