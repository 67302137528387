'use client';

import { useEffect, useState } from 'react';

type ShareButtonProps = React.ComponentPropsWithoutRef<'button'> & {
  title?: string;
  url?: string;
  children?: React.ReactNode;
};

export default function ShareButton({
  url = window.location.href,
  title = document.title,
  children,
  ...props
}: ShareButtonProps) {
  const [isClient, setIsClient] = useState(false);
  useEffect(() => setIsClient(true), []);
  const navigator = isClient ? window.navigator : null;

  if (navigator && !navigator.share) {
    console.warn('Web Share API not supported in this browser.');
    return null;
  }

  return (
    <button aria-label="Share" {...props} onClick={() => share(title, url)}>
      {children || (
        <svg
          width="22"
          height="31"
          viewBox="0 0 22 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          role="presentation"
        >
          <path
            d="M4.47826 12H3C1.89543 12 1 12.8954 1 14V28C1 29.1046 1.89543 30 3 30H19C20.1046 30 21 29.1046 21 28V14C21 12.8954 20.1046 12 19 12H17.5714"
            stroke="currentColor"
            strokeWidth="2"
          />
          <path d="M17 8L11 2L5 8" stroke="currentColor" strokeWidth="2" />
          <path d="M11 2V23" stroke="currentColor" strokeWidth="2" />
        </svg>
      )}
    </button>
  );
}

function share(title: string, url: string) {
  navigator
    .share({
      title: title,
      url: url,
    })
    .catch((error) => {
      if (error instanceof DOMException && error.name === 'AbortError') {
        console.info('Share aborted', error);
      } else {
        console.error('Error sharing', error);
      }
    });
}
