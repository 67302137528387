'use client';

import { useCallback, useRef } from 'react';
import {
  InstantSearch,
  Configure,
  ClearRefinements,
  Pagination,
  SearchBox,
} from 'react-instantsearch';
import type { Block_PeopleSearch } from '@/types/fragments/mainNeo';
import { getPeopleRouting, searchClient } from '@/lib/algolia';
import CurrentRefinements from '@/components/search/CurrentRefinements';
import Hits from '@/components/search/Hits';
import { RefinementPanel, VirtualRefinementPanel } from '@/components/search/RefinementPanel';
import SubmitIcon from '@/components/search/SubmitIcon';

// Styles (for dev)
// import 'instantsearch.css/themes/reset.css';
// import 'instantsearch.css/themes/satellite.css';

const environment = process.env.NEXT_PUBLIC_ENVIRONMENT as 'dev' | 'staging' | 'production';
const indexName = `${environment}_people` as const;
const routing = getPeopleRouting(indexName);

export default function PeopleSearch({ anchor }: Block_PeopleSearch) {
  const rootRef = useRef<HTMLElement | null>(null);

  const onPaginationClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (!(e.target instanceof HTMLElement)) return;

    const item = e.target.classList.contains('ais-Pagination-item')
      ? e.target
      : e.target.closest('.ais-Pagination-item');
    if (!item || item.classList.contains('disabled')) return;

    rootRef.current?.scrollIntoView();
  }, []);

  return (
    <section id={anchor || undefined} ref={rootRef} aria-label="People search">
      {/*
        Not using InstantSearchNext as it would require dynamic rendering based on query params, which
        aren't available at build time.
      */}
      <InstantSearch
        searchClient={searchClient}
        indexName={indexName}
        routing={routing}
        future={{
          preserveSharedStateOnUnmount: true,
        }}
      >
        <Configure hitsPerPage={30} distinct={true} />

        {/* ====== START REFINEMENTS ====== */}
        <div className="mb-8 bg-brabners-navy py-10 text-white xs:mb-5">
          <div className="container">
            <div className="mb-8 grid items-center gap-x-20 gap-y-5 lg:grid-flow-col">
              <RefinementPanel label="Services" attribute="services" />
              <VirtualRefinementPanel attribute="services" />
              <VirtualRefinementPanel attribute="subServices" />
              <RefinementPanel label="Sectors" attribute="sectors" />
              <VirtualRefinementPanel attribute="sectors" />
              <VirtualRefinementPanel attribute="subSectors" />
              <RefinementPanel label="Locations" attribute="locations" />
              <VirtualRefinementPanel attribute="locations" />

              <div className="order-first lg:order-none">
                <SearchBox
                  submitIconComponent={SubmitIcon}
                  placeholder="Search..."
                  classNames={{
                    root: 'min-w-[18.75rem]',
                    form: 'relative',
                    input: 'bg-brabners-navy-200 text-white w-full pr-14 py-1.5',
                    reset: 'hidden',
                    submit: '-translate-y-1/2 absolute right-3 top-1/2',
                  }}
                />
              </div>
            </div>
            {/* ====== START CURRENT REFINEMENTS ====== */}
            <div className="xs:flex xs:min-h-11 xs:items-center">
              <CurrentRefinements />
              <ClearRefinements
                classNames={{
                  root: 'text-center text-lg lg:text-xl font-bold xs:ml-auto xs:text-start text-brabners-copper-300 min-w-28',
                  disabledButton: 'hidden',
                }}
                translations={{
                  resetButtonText: 'Clear filters',
                }}
              />
            </div>
            {/* ====== END CURRENT REFINEMENTS ====== */}
          </div>
        </div>
        {/* ====== END REFINEMENTS ====== */}

        {/* ====== START HITS ====== */}
        <Hits
          classNames={{
            root: 'container max-w-slim my-[3.75rem]',
            list: 'grid gap-y-14 gap-x-28 justify-center md:grid-cols-2 lg:grid-cols-3',
            item: 'min-h-[21.25rem] w-full max-w-2xs',
          }}
        />
        {/* ====== END HITS ====== */}

        {/* ====== START PAGINATION ====== */}
        <Pagination
          classNames={{
            list: 'flex items-center justify-center gap-1 xs:gap-2 mb-10 text-lg xs:text-xl',
            item: 'group w-7 h-7 rounded-full transition-colors duration-300 text-center',
            selectedItem: 'bg-brabners-copper text-white',
            disabledItem: 'disabled cursor-not-allowed opacity-60 text-murg-grey',
          }}
          showFirst={false}
          showLast={false}
          onClick={onPaginationClick}
        />
        {/* ====== END PAGINATION ====== */}
      </InstantSearch>
    </section>
  );
}
