'use client';

import type { Block_Map } from '@/types/fragments/mainNeo';
import type { Cookiebot } from '@/types/cookiebot';
import { useEffect, useRef } from 'react';
import SanitizeHtml from '@/components/utils/SantizeHtml';
import useConsent from '@/lib/hooks/useConsent';

const markerColour = '#B2432D';

// React mapbox example: https://docs.mapbox.com/help/tutorials/use-mapbox-gl-js-with-react/

export default function MapBlock({
  heading,
  headingElement,
  richText,
  marker: markers,
  zoomLevel,
  anchor,
}: Block_Map) {
  const Heading = headingElement as keyof React.JSX.IntrinsicElements;
  const consent = useConsent();
  const hasConsented = consent?.statistics ?? false;

  // Using refs as these values shouldn't change so we don't need them to be reactive and trigger a
  // re-render
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const zoomRef = useRef(zoomLevel);
  const markerRef = useRef(markers[0]);

  useEffect(() => {
    if (!markerRef.current) return; // Invalid marker - bail
    // Bail if consent not given. Page reload handles consent being withdrawn, otherwise we'd need
    // to handle this here.
    if (!hasConsented) return;

    if (mapRef.current) return; // Initialize map only once
    const coords: [number, number] = [markerRef.current.longitude, markerRef.current.latitude]; // lng, lat for some reason

    const loadMapbox = async () => {
      const [{ default: mapboxgl }] = await Promise.all([
        import('mapbox-gl'),
        import('mapbox-gl/dist/mapbox-gl.css'),
      ]);
      mapboxgl.accessToken = process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN as string;

      mapRef.current = new mapboxgl.Map({
        container: mapContainer.current!,
        style: process.env.NEXT_PUBLIC_MAPBOX_STYLE_URL as string,
        center: coords,
        zoom: zoomRef.current,
        scrollZoom: false,
      });
      // disable map rotation using right click + drag
      // map.dragRotate.disable();
      // // disable map rotation using touch rotation gesture
      // map.touchZoomRotate.disableRotation();
      // Add zoom and rotation controls to the map.
      mapRef.current.addControl(new mapboxgl.NavigationControl());

      new mapboxgl.Marker({ color: markerColour }).setLngLat(coords).addTo(mapRef.current);
    };

    loadMapbox();

    return () => {
      mapRef.current?.remove();
    };
  }, [hasConsented]);

  if (!markerRef.current) return null;

  return (
    <section
      id={anchor || undefined}
      className={hasConsented ? 'relative' : 'bg-brabners-navy-300 py-5 text-white'}
    >
      {hasConsented ? (
        <>
          {(heading || richText) && (
            <div className="absolute left-0 right-0 top-[60%] z-[1] mx-auto w-full max-w-fit rounded-md bg-white p-6 lg:left-auto lg:right-[5%] lg:top-[40%] lg:mx-0 lg:max-w-sm">
              {heading && (
                <Heading
                  className={`heading ${richText ? 'mb-2' : ''} text-start text-xl lg:text-3xl`}
                >
                  {heading}
                </Heading>
              )}
              {richText && <SanitizeHtml className="rich-text" innerHtml={richText} />}
            </div>
          )}
          <div ref={mapContainer} className="map-container h-[40rem]" />
        </>
      ) : (
        <div className="container flex min-h-48 items-center justify-center lg:min-h-80">
          <p>
            Please{' '}
            <button
              className="hover:underline"
              onClick={() => {
                (window.Cookiebot as Cookiebot).renew();
              }}
            >
              allow statistics cookies
            </button>{' '}
            to view this map.
          </p>
        </div>
      )}
    </section>
  );
}
