import type { FieldTypeProps } from '@/types/forms';
import type { FormField_Entries } from '@/types/fragments/form';
import Checkboxes from './Checkboxes';
import Dropdown from './Dropdown';
import Radio from './Radio';

type DisplayField = typeof Checkboxes | typeof Dropdown | typeof Radio;

export default function Entries({ field }: FieldTypeProps<FormField_Entries>) {
  const options = field.entries.map((option) => ({ label: option.title, value: option.id }));
  const suffix = field.displayType === 'checkboxes' ? [] : [''];

  const Component: DisplayField =
    field.displayType === 'checkboxes'
      ? Checkboxes
      : field.displayType === 'dropdown'
        ? Dropdown
        : Radio;

  /*
    `field` resolves to `Field_A & Field_B`, which is `never`, instead of `Field_A | Field_B`
    like you'd expect. Possibly a case of "correspondance problem":
    https://discord.com/channels/508357248330760243/1206402189942198362/1206427113423376394
  */
  return <Component field={{ ...field, options } as never} namespaceSuffix={suffix} />;
}
