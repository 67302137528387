'use client';

import type { Block_CaseHighlights } from '@/types/fragments/mainNeo';
import Link from 'next/link';
import { getImageSources } from '@/lib/craftImage';
import getColourScheme from '@/lib/theme';
import { craftUriToPath } from '@/lib/utils';

// Components
import SwiperCarousel from '@/components/Carousel';
import Picture from '@/components/Picture';
import NlToBr from '@/components/utils/NlToBr';
import WaveVerticalGraphicRight from '@/components/icons/WaveVerticalGraphicRight.svg';

/**
 * Swiper uses a shadow dom so for anything other than CSS variables we need to use `injectStyles`.
 */
const swiperStyles = `@media (max-width: 767px) {
  .swiper-button-prev,
  .swiper-button-next {
    top: auto;
    bottom: var(--swiper-pagination-bottom, 8px);
    z-index: 20;
  }
}

@media (max-width: 1023x) {
  .swiper-button-prev,
  .swiper-button-next {
    top: auto;
    bottom: var(--swiper-pagination-bottom, 8px);
    z-index: 20;
  }
}

@media (min-width: 1024px) {
  .swiper-button-prev {
    left: calc((25rem + ((100dvw - 75rem) / 2)) + var(--swiper-navigation-sides-offset, 10px));
  }

  .swiper-pagination-bullets.swiper-pagination-horizontal {
    width: calc(50rem + ((100dvw - 75rem) / 2));
    left: auto;
    right: 0;
  }
}`;

export default function CaseHighlightsBlock({
  heading,
  headingElement,
  caseHighlights,
  anchor,
}: Block_CaseHighlights) {
  const Heading = (headingElement as keyof React.JSX.IntrinsicElements) || 'h2';
  const theme = getColourScheme('blue');

  return (
    <section
      id={anchor || undefined}
      // Use cream by default for both Brabners person and default theme, for alt style use pink for
      // Brabners personal and blue for default theme.
    >
      <Heading className="heading mb-7 pt-8 text-center text-4xl md:pt-12 lg:text-5xl">
        {heading || 'Case Highlights'}
      </Heading>
      <div className={`${theme.bg} ${theme.text} ${theme.heading}`}>
        {caseHighlights.length > 1 ? (
          <SwiperCarousel
            paramsAsProps={{
              injectStyles: [swiperStyles],
            }}
            navigation={true}
            class={`${theme.swiperTheme} ${theme.swiperPaginationInactive} [--swiper-navigation-sides-offset:20%] [--swiper-pagination-bottom:3.125rem] md:[--swiper-navigation-sides-offset:6%] md:[--swiper-pagination-bottom:2.5rem]`}
          >
            {caseHighlights.map((caseHighlight) => (
              <swiper-slide class="h-auto" key={caseHighlight.id}>
                <CaseHighlight {...caseHighlight} />
              </swiper-slide>
            ))}
          </SwiperCarousel>
        ) : (
          caseHighlights.map((caseHighlight) => (
            <CaseHighlight key={caseHighlight.id} {...caseHighlight} />
          ))
        )}
      </div>
    </section>
  );
}

function CaseHighlight({
  id,
  heading,
  multiLineText,
  entry: entries,
  fallbackImage: fallbackImages,
  ...rawImageSources
}: Block_CaseHighlights['caseHighlights'][number]) {
  const iconTheme = getColourScheme('blue');
  const fallbackImage = fallbackImages[0];
  const entry = entries[0];
  return (
    <div className="relative h-full md:grid md:grid-cols-1-2">
      {fallbackImage && (
        <Picture
          sources={getImageSources('image', rawImageSources)}
          imageProps={{
            src: fallbackImage.url,
            alt: fallbackImage.alt || fallbackImage.title,
            className: 'aspect-[8/7] w-full h-full object-cover',
            width: fallbackImage.width,
            height: fallbackImage.height,
          }}
        />
      )}
      {!fallbackImage && (
        <div
          className={`relative grid min-h-80 place-items-center overflow-hidden text-brabners-cream md:min-h-[auto] ${iconTheme.bg}`}
        >
          <WaveVerticalGraphicRight
            height="100%"
            className={`absolute left-1/2 top-0 -translate-x-1/2 ${iconTheme.pattern}`}
          />
          {/* Brabners B */}
          <svg
            width="100%"
            viewBox="0 0 183 192"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            role="img"
            aria-label="Brabners B logo"
            // relative to position infront of wave
            className="relative -left-1 block max-h-[36%] max-w-[min(36%,11.5rem)]"
          >
            <path
              d="M142.253 86.9848C162.388 81.3749 176.336 65.5388 176.336 47.2185C176.336 31.0512 169.963 16.954 155.394 9.48097C138.404 0.745229 111.336 0 86.0677 0C39.133 0 0 4.61628 0 4.61628V11.6132L1.63485 11.5718C4.0354 11.5718 15.9553 12.1721 16.0174 23.8473V169.726C16.0174 181.484 0 182.043 0 182.043V189.04C0 189.04 75.3481 192 83.3361 192C111.087 192 131.74 191.027 146.475 186.535C176.254 177.468 183 158.692 183 137.371C183 114.455 164.975 92.9052 142.232 86.9848M90.6205 83.859H73.7753V11.4268C73.7753 11.4268 73.8374 9.77078 82.3221 9.77078C105.603 9.77078 116.302 27.7598 116.302 47.8189C116.302 67.8779 105.024 83.859 90.6205 83.859ZM92.0484 90.8351C106.886 90.8351 119.427 112.467 119.427 138.095C119.427 163.723 109.245 182.685 83.2947 182.685C76.7346 182.685 73.7753 181.298 73.7753 181.298V90.8558H92.0484V90.8351Z"
              fill="currentColor"
            />
          </svg>
        </div>
      )}
      <div className="px-10 pb-32 pt-10 md:px-24 md:py-32">
        <div className="mx-auto flex h-full max-w-2xl flex-col items-center justify-center text-center">
          <p className="heading mb-6 text-4xl lg:text-5xl">{heading}</p>
          <p>{multiLineText && <NlToBr text={multiLineText} />}</p>
          {entry && (
            <Link
              href={craftUriToPath(entry.uri)}
              className="mt-7 flex items-center font-bold text-brabners-heading"
            >
              Read more
              <div className="ml-3.5 overflow-hidden">
                <svg
                  width="56"
                  height="14"
                  viewBox="0 0 56 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  role="presentation"
                  className="-translate-x-1/3 transition-transform duration-300 motion-safe:group-has-[a:hover]:translate-x-0"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M51.5391 6.28345L47.1387 2.00002L48.5338 0.566895L55.4336 7.28345L48.5338 14L47.1387 12.5669L51.5391 8.28345H0V6.28345H51.5391Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
