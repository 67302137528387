'use client';

import type { SwiperContainer as HTMLSwiperContainerElement } from 'swiper/element';
import type { SwiperOptions } from 'swiper/types';
import { useEffect, useRef } from 'react';
import { register as registerSwiperElements } from 'swiper/element/bundle';

registerSwiperElements();

type CarouselProps = React.ComponentPropsWithoutRef<'swiper-container'> & {
  children: React.ReactNode;
  /**
   * Options which be passed as props with JS, rather than HTML attributes. Supports more complex
   * options which require objects. Attributes take precedence over props except for breakpoints,
   * ie. props.breakpoints > attributes > props. https://swiperjs.com/element#parameters-as-props
   */
  paramsAsProps?: SwiperOptions;
};

const defaultAttrs: React.ComponentPropsWithoutRef<'swiper-container'> = {
  speed: 1000,
  'slides-per-view': 1,
  'space-between': 7,
  'centered-slides': true,
  pagination: true,
};

export default function Carousel({
  children,
  paramsAsProps,
  init = !paramsAsProps,
  ...paramsAsAttributes
}: CarouselProps) {
  const ref = useRef<HTMLSwiperContainerElement | null>(null);

  useEffect(() => {
    const swiperEl = ref.current!;
    if (!paramsAsProps) return;
    Object.assign(swiperEl, paramsAsProps);
    swiperEl.initialize();
  }, [paramsAsProps]);

  return (
    <swiper-container {...defaultAttrs} {...paramsAsAttributes} init={init} ref={ref}>
      {children}
    </swiper-container>
  );
}
