import type { FormField } from '@/types/fragments/form';
import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';

type FieldInstructionsProps = {
  field: FormField;
  position?: Position;
};

export default function FieldInstructions({
  field,
  position = 'above-input',
}: FieldInstructionsProps) {
  if (!field.instructions || !canShow(field, position)) return null;

  return (
    <p className={`${position === 'above-input' ? 'mb-2' : 'mt-2'} text-sm text-gray-400`}>
      {field.instructions}
    </p>
  );
}

type Position = 'above-input' | 'below-input';

/**
 * Not using useState here because this doesn't need to re-render. This is similar to v-once in Vue.
 */
function canShow(field: FormField, position: Position): boolean {
  const className = `verbb\\formie\\positions\\${upperFirst(camelCase(position))}`;

  if (field.instructionsPosition === className) {
    return true;
  } else if (!field.instructionsPosition && position === 'above-input') {
    return true;
  } else {
    return false;
  }
}
