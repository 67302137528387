import trim from 'lodash/trim';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isObject(obj: any): obj is object {
  return obj !== null && typeof obj === 'object';
}

export function isUrlExternal(url: string): boolean {
  try {
    const baseUrl = process.env.NEXT_PUBLIC_SITE_URL!.toLowerCase();
    const parsedUrl = new URL(url, baseUrl);
    return parsedUrl.origin !== baseUrl;
  } catch (e) {
    console.error('Error parsing URL:', url);
    return false;
  }
}

export function removeDomainFromUrlIfInternal(url: string): string {
  try {
    const baseUrl = process.env.NEXT_PUBLIC_SITE_URL!.toLowerCase();
    const parsedUrl = new URL(url, baseUrl);

    if (parsedUrl.origin !== baseUrl) {
      return url;
    }

    return `${parsedUrl.pathname}${parsedUrl.search}${parsedUrl.hash}`;
  } catch (e) {
    console.error('Error parsing URL with domain:', url);
    return url;
  }
}

export function pathToCraftUri(path: string): string {
  return path === '/' ? '__home__' : trim(path.toLowerCase(), '/');
}

export function craftUriToPath(uri: string): string {
  return uri === '__home__' ? '/' : `/${uri}`;
}

/**
 * Spacing utility function that takes values from the CMS and returns the appropriate Tailwind CSS
 * classes
 */
export function getSpacingClasses(value: string): string {
  switch (value) {
    case '0': {
      return 'py-0';
    }
    case '6': {
      return 'py-4 md:py-6';
    }
    case '12': {
      return 'py-8 md:py-12';
    }
    case '20': {
      return 'py-12 md:py-20';
    }
    default: {
      return 'py-8 md:py-12';
    }
  }
}
