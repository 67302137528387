'use client';

import type AnimeJS from 'animejs';
import { useEffect, useRef, useState } from 'react';
import useMedia from '@/lib/hooks/useMedia';

import type { FeatureAnimationProps } from '../FeatureHero';
import Picture from '@/components/Picture';
import { getImageSources } from '@/lib/craftImage';
import WaveSwirlLeft from '@/components/icons/WaveSwirlLeft.svg';

let animeJsPromise: Promise<{ default: typeof AnimeJS }> | null = null;

const HealthAnimation = ({ fallbackImage, rawImageSources, className }: FeatureAnimationProps) => {
  /**
   * The watch strap, positioned behind the wave pattern
   */
  const strapFallbackImage = fallbackImage.relatedFeatureAssetParts[0];

  const isMotionSafe = useMedia('(prefers-reduced-motion: no-preference)');
  const [isInitialised, setIsInitialised] = useState(false);

  useEffect(() => {
    if (isInitialised || !isMotionSafe) return;

    const initAnimeJs = async () => {
      // Only import once globally for all component instances
      if (!animeJsPromise) {
        animeJsPromise = import('animejs/lib/anime.es');
      }

      const { default: anime } = await animeJsPromise;
      if (isInitialised) return; // Check again

      const tl = anime.timeline({
        easing: 'easeOutSine',
        delay: 2000,
      });
      tl.add({
        targets: ref.current!.querySelectorAll('.anime-liftup'),
        translateY: '-20%',
        duration: 1500,
      }),
        tl.add(
          {
            targets: ref.current!.querySelectorAll('.anime-shadowLiftup'),
            scaleX: 0.6,
            scaleY: 0.8,
            duration: 1500,
          },
          0,
        ),
        tl.add({
          targets: ref.current!.querySelectorAll('.anime-svg path'),
          strokeDashoffset: [anime.setDashoffset, 0],
          duration: 1500,
          delay: 0,
        });
      setIsInitialised(true);
    };
    initAnimeJs();
  }, [isInitialised, isMotionSafe]);

  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <div className="relative" ref={ref}>
      <div className="anime-liftup relative">
        <WaveSwirlLeft
          className={`h-100vh anime-svg absolute right-0 top-0 z-20 translate-x-[23%] md:-translate-y-[15%] md:translate-x-[40%] ${className}`}
        />
        <Picture
          className="relative top-4 z-30"
          sources={getImageSources('health', rawImageSources)}
          priority={true}
          imageProps={{
            src: fallbackImage.url,
            alt: fallbackImage.alt || fallbackImage.title,
            // No fixed aspect ratio
            className: 'object-cover',
            loading: 'eager',
            decoding: 'async',
            width: fallbackImage.width,
            height: fallbackImage.height,
          }}
        />
        <Picture
          className="absolute left-0 right-0 top-4 z-10"
          sources={[]}
          priority={true}
          imageProps={{
            src: strapFallbackImage.url,
            alt: strapFallbackImage.alt || strapFallbackImage.title,
            // No fixed aspect ratio
            className: 'object-cover',
            loading: 'eager',
            decoding: 'async',
            width: strapFallbackImage.width,
            height: strapFallbackImage.height,
          }}
        />
      </div>
      <span className="anime-shadowLiftup absolute h-12 w-full translate-y-[100%] scale-100 transform-gpu rounded-[35%] bg-black bg-opacity-50 mix-blend-multiply blur" />
    </div>
  );
};

export default HealthAnimation;
