'use client';

import type AnimeJS from 'animejs';
import { useEffect, useRef, useState } from 'react';
import useMedia from '@/lib/hooks/useMedia';

import type { FeatureAnimationProps } from '../FeatureHero';

import Picture from '@/components/Picture';
import { getImageSources } from '@/lib/craftImage';
import WaveSwirlVertical from '@/components/icons/WaveSwirlVertical.svg';
import WaveSwirlRight from '@/components/icons/WaveSwirlRight.svg';

let animeJsPromise: Promise<{ default: typeof AnimeJS }> | null = null;

const RetailAnimation = ({ fallbackImage, rawImageSources, className }: FeatureAnimationProps) => {
  /**
   * The back of the bag, positioned behind the wave pattern
   */
  const backFallbackImage = fallbackImage.relatedFeatureAssetParts[0];

  const isMotionSafe = useMedia('(prefers-reduced-motion: no-preference)');
  const [isInitialised, setIsInitialised] = useState(false);

  useEffect(() => {
    if (isInitialised || !isMotionSafe) return;

    const initAnimeJs = async () => {
      // Only import once globally for all component instances
      if (!animeJsPromise) {
        animeJsPromise = import('animejs/lib/anime.es');
      }

      const { default: anime } = await animeJsPromise;
      if (isInitialised) return; // Check again

      const tl = anime.timeline({
        easing: 'easeOutSine',
      });
      tl.add({
        targets: ref.current!.querySelectorAll('.anime-container'),
        translateY: '-20%',
        duration: 1000,
        delay: 2000,
      }),
        tl.add(
          {
            targets: ref.current!.querySelectorAll('.anime-shadowLiftup'),
            scaleX: 0.6,
            scaleY: 0.8,
            duration: 1000,
            delay: 2000,
          },
          0,
        ),
        tl.add({
          targets: ref.current!.querySelectorAll('.anime-svg'),
          opacity: [0, 1],
          duration: 0.75,
        }),
        tl.add({
          targets: ref.current!.querySelectorAll('.anime-svg path'),
          strokeDashoffset: [anime.setDashoffset, 0],
          duration: 3000,
        }),
        tl.add(
          {
            targets: ref.current!.querySelectorAll('.anime-svg-lg path'),
            strokeDashoffset: [anime.setDashoffset, 0],
            duration: 2000,
          },
          '-=2000',
        );
      setIsInitialised(true);
    };

    initAnimeJs();
  }, [isInitialised, isMotionSafe]);

  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <div className="relative" ref={ref}>
      <WaveSwirlRight
        className={`anime-svg-lg absolute right-0 top-0 -translate-x-[20%] -rotate-90 transform-gpu md:-translate-x-[25%] ${className}`}
      />
      <div className="anime-container relative z-10 transform-gpu">
        <WaveSwirlVertical
          className={`anime-svg absolute right-0 top-0 z-20 mx-auto -translate-y-[70%] translate-x-[14.5rem] transform-gpu opacity-0 md:mx-0 md:w-auto md:-translate-y-[65%] ${className}`}
        />
        <Picture
          className="relative top-4 z-30 mx-auto w-[80%] md:mx-0 md:w-auto"
          sources={getImageSources('retail', rawImageSources)}
          priority={true}
          imageProps={{
            src: fallbackImage.url,
            alt: fallbackImage.alt || fallbackImage.title,
            // No fixed aspect ratio
            className: 'object-cover',
            loading: 'eager',
            decoding: 'async',
            width: fallbackImage.width,
            height: fallbackImage.height,
          }}
        />
        <Picture
          className="absolute left-0 right-0 top-4 z-10 mx-auto w-[80%] md:mx-0 md:w-auto"
          sources={[]}
          priority={true}
          imageProps={{
            src: backFallbackImage.url,
            alt: backFallbackImage.alt || backFallbackImage.title,
            // No fixed aspect ratio
            className: 'object-cover',
            loading: 'eager',
            decoding: 'async',
            width: backFallbackImage.width,
            height: backFallbackImage.height,
          }}
        />
      </div>
      <span className="anime-shadowLiftup absolute z-0 h-12 w-full transform-gpu rounded-[35%] bg-black bg-opacity-50 mix-blend-multiply blur" />
    </div>
  );
};

export default RetailAnimation;
