import { Fragment } from 'react';

type NlToBrProps = { text: string };

export default function NlToBr({ text }: NlToBrProps): React.JSX.Element {
  const textParts = text.split('\n');

  return (
    <>
      {textParts.map((item, i) => (
        <Fragment key={i}>
          {item}
          {i < textParts.length - 1 && <br />}
        </Fragment>
      ))}
    </>
  );
}
