import type { FormField as FormFieldQueryData } from '@/types/fragments/form';
import type {
  SubField as FormSubFieldQueryData,
  FieldWithSubfields as FormFieldWithSubfields,
  SubFieldType as FormSubFieldType,
  FieldTypeProps,
} from '@/types/forms';
import { attributesTable } from './utils';

export default function useFieldAttrs<F extends FormFieldQueryData>(fieldProps: FieldTypeProps<F>) {
  const { namespace = [], field, namespaceSuffix = [] } = fieldProps;

  function getNamespaceParts(extraParts: string[] = []) {
    return [...namespace, field.handle, ...extraParts, ...namespaceSuffix];
  }

  function getId(extraHandles: string[] = []) {
    return `${getNamespaceParts(extraHandles).join('-')}`;
  }

  function getName(extraHandles: string[] = []): string {
    const namespaceParts = getNamespaceParts(extraHandles);
    const first = namespaceParts.shift() as string;

    if (namespaceParts.length) {
      return `${first}[${namespaceParts.join('][')}]`;
    }

    return first;
  }

  function getAttrs() {
    return {
      id: getId(),
      name: getName(),
      required: field.required,
      // These may be null
      placeholder: field.placeholder || undefined,
      defaultValue: field.defaultValue || undefined,
      ...attributesTable(field.inputAttributes || []),
    };
  }

  type S = F extends FormFieldWithSubfields ? FormSubFieldQueryData<F, FormSubFieldType<F>> : never;

  const getSubFieldAttrs = hasSubField(field)
    ? (subField: S) => ({
        id: getId([subField.handle]),
        name: getName([subField.handle]),
        required: subField.required,
        // These may be null
        placeholder: subField.placeholder || '',
        defaultValue: subField.defaultValue || '',
        ...attributesTable(field.inputAttributes || []),
      })
    : null;

  return [getId, getName, getAttrs, getSubFieldAttrs] as const;
}

function hasSubField(field: FormFieldQueryData): field is FormFieldWithSubfields {
  return ['Field_Address', 'Field_Name'].includes(field.__typename);
}
