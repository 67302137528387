'use client';

import type {
  Form as FormData,
  FormPage as FormPageData,
  FormField as FormFieldData,
} from '@/types/fragments/form';
import { forwardRef } from 'react';
import FormField from './FormField';

type FormPageProps = {
  form: FormData;
  page: FormPageData;
  pageIndex: number;
  loading: boolean;
  activePage: number;
  setActivePage: (newValue: number) => void;
};

function FormPage(
  { form, page, pageIndex, loading, activePage, setActivePage }: FormPageProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  // ============================== Derived state
  const positionClass = positionMap[page.settings.buttonsPosition as ButtonPosition] || '';

  // ============================== Event handlers
  const onBack = () => {
    setActivePage(activePage - 1);
  };

  return (
    <div ref={ref} className={pageIndex === activePage ? '' : 'hidden'}>
      {form.settings.displayCurrentPageTitle && (
        <h3 className="heading mb-7 text-center text-2xl text-brabners-copper-300">{page.name}</h3>
      )}

      {page.rows.map((row, rowIndex) => (
        <div key={rowIndex} className="flex flex-col flex-wrap">
          {(row.rowFields as FormFieldData[]).map((field, fieldIndex) => (
            <FormField key={fieldIndex} field={field} />
          ))}
        </div>
      ))}

      <div className={`flex py-3 ${positionClass}`}>
        {page.settings.showBackButton && (
          <button
            type="button"
            className="btn secondary mr-4 focus:ring-offset-brabners-navy-400"
            onClick={onBack}
          >
            {page.settings.backButtonLabel}
          </button>
        )}

        <button
          disabled={loading}
          type="submit"
          className={`btn on-dark focus:ring-offset-brabners-navy-400 ${loading ? 'loading' : ''}`}
        >
          {page.settings.submitButtonLabel}
        </button>
      </div>
    </div>
  );
}

export default forwardRef<HTMLDivElement, FormPageProps>(FormPage);

type ButtonPosition = 'left-right' | 'center' | 'right';
type PagePosition = 'justify-between' | 'justify-center' | 'justify-end';

const positionMap: Record<ButtonPosition, PagePosition> = {
  'left-right': 'justify-between',
  center: 'justify-center',
  right: 'justify-end',
};
