import type { FieldTypeProps, SubFieldType } from '@/types/forms';
import type { FormField_Address } from '@/types/fragments/form';
import { getSubFields } from '../utils';
import useFieldAttrs from '../fieldAttrs';
import FieldInstructions from './partials/FieldInstructions';
import FieldLabel from './partials/FieldLabel';

const autocomplete = {
  Field_Address1: 'address-line1',
  Field_Address2: 'address-line2',
  Field_Address3: 'address-line3',
  Field_City: 'address-level2',
  Field_Zip: 'postal-code',
  Field_State: 'address-level1',
  Field_Country: 'country',
};

/**
 * Split subfields into groups
 */
const subFieldTypeGroups: SubFieldType<FormField_Address>[][] = [
  ['address1'],
  ['address2'],
  ['address3'],
  ['city', 'zip'],
  ['state', 'country'],
];

type AddressFieldProps = FieldTypeProps<FormField_Address>;

export default function Address(props: AddressFieldProps) {
  const { field } = props;

  const subFieldsGroups = subFieldTypeGroups.map((g) => getSubFields(field, g));

  // ============================== Hooks
  const [getId, getName, , getSubFieldAttrs] = useFieldAttrs<FormField_Address>(props);

  return (
    <fieldset className="-mb-5">
      {/* Statically hidden until subfieldLabelPosition is added to gql schema - https://github.com/verbb/formie/issues/1797 */}
      {/* <legend className="mb-1 block text-sm">{field.name}</legend> */}

      <FieldInstructions field={field} position="above-input" />

      {subFieldsGroups.map((subFields, groupIndex) => (
        <div key={groupIndex} className="-mx-2 flex flex-col flex-wrap">
          {subFields.map((subField) => {
            const { __typename, handle } = subField;
            const id = getId([handle]);
            const useSelect = __typename === 'Field_Country';
            return (
              <div key={__typename} className="mb-5 flex-1 px-2">
                <FieldLabel<FormField_Address>
                  id={id}
                  field={field}
                  subField={subField}
                  position="above-input"
                />

                {useSelect ? (
                  <select
                    id={id}
                    name={getName([handle])}
                    autoComplete={autocomplete[__typename]}
                    className="block w-full appearance-none rounded-[1.25rem] bg-brabners-navy-200 bg-select bg-no-repeat py-2 pl-5 pr-8 text-sm"
                  >
                    {subField.options.map((option, j) => (
                      <option key={j} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                ) : (
                  <input
                    {...getSubFieldAttrs!(subField)}
                    autoComplete={autocomplete[__typename]}
                    type="text"
                    className="block w-full rounded-[1.25rem] bg-brabners-navy-200 px-5 py-2 text-sm"
                  />
                )}

                <FieldLabel<FormField_Address>
                  id={id}
                  field={field}
                  subField={subField}
                  position="below-input"
                />
              </div>
            );
          })}
        </div>
      ))}

      <FieldInstructions field={field} position="below-input" />
    </fieldset>
  );
}
