'use client';

import { useEffect, useState } from 'react';
import Script from 'next/script';

type HubspotEmbedProps = { formId: string };

export default function HubspotEmbed({ formId }: HubspotEmbedProps) {
  const id = `hbspt-form-${getHtmlId(formId)}`;
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!isLoaded) return;
    window.hbspt!.forms.create({
      portalId: '24983590',
      region: 'eu1',
      formId: formId,
      target: `#${id}`,
    });
    // Don't need id in dependencies as formId is derived from it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, formId]);

  return (
    <>
      <Script
        id="hubspot-embed"
        data-cookieconsent="necessary"
        src="https://js-eu1.hsforms.net/forms/embed/v2.js"
        onReady={() => {
          setIsLoaded(true);
        }}
      />
      {isLoaded ? (
        <div id={id} />
      ) : (
        <p className="flex items-center justify-center">
          <span
            className="inline-block size-8 animate-spin rounded-full border-[3px] border-current border-t-transparent text-brabners-copper"
            role="status"
            aria-label="Loading"
          />{' '}
          <span className="ml-3">Loading form...</span>
        </p>
      )}
      <div id={id} />
    </>
  );
}

function getHtmlId(str: string): string {
  return str.replace(/^[^a-z]+|[^\w:.-]+/gi, '-');
}
