import SanitizeHtml from '@/components/utils/SantizeHtml';

export type AlertState = 'success' | 'error' | null;

type FormAlertProps = {
  messageHtml: string;
  alertState: AlertState;
  setAlertState: (newValue: AlertState) => void;
};

export default function FormAlert({ messageHtml, alertState, setAlertState }: FormAlertProps) {
  if (alertState === null) {
    return null;
  }

  // ============================== Event handlers
  function onClose() {
    setAlertState(null);
  }

  // ============================== Derived state
  const isSuccess = alertState === 'success';
  const iconPath = isSuccess
    ? 'M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
    : 'M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z';

  return (
    <div className={`mb-5 rounded-md p-4 sm:px-6 ${isSuccess ? 'bg-green-50' : 'bg-red-50'}`}>
      <div className="flex items-center">
        <svg
          className={`h-6 w-6 ${isSuccess ? 'text-green-400' : 'text-red-400'}`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          role="presentation"
        >
          <path fillRule="evenodd" d={iconPath} clipRule="evenodd" />
        </svg>

        <SanitizeHtml
          className={`ml-5 text-base font-semibold leading-normal ${isSuccess ? 'text-green-800' : 'text-red-700'}`}
          innerHtml={messageHtml}
        />

        <div className="ml-5">
          <div className="-mx-1.5 -my-1.5">
            <DismissButton onClose={onClose} isSuccess={isSuccess} />
          </div>
        </div>
      </div>
    </div>
  );
}

type DismissButton = {
  onClose: () => void;
  isSuccess: boolean;
};

function DismissButton({ onClose, isSuccess }: DismissButton) {
  const className = isSuccess
    ? 'bg-green-50 text-green-500 hover:bg-green-100 focus:ring-green-600 focus:ring-offset-green-50'
    : 'bg-red-50 text-red-500 hover:bg-red-100 focus:ring-red-600 focus:ring-offset-red-50';

  return (
    <button
      type="button"
      className={`inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2 ${className}`}
      onClick={onClose}
    >
      <span className="sr-only">Dismiss</span>
      <svg
        className={`h-5 w-5 ${isSuccess ? 'text-green-400' : 'text-red-400'}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        role="presentation"
      >
        <path
          fillRule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clipRule="evenodd"
        />
      </svg>
    </button>
  );
}
