import Link from 'next/link';
import type { ImageData } from '../Picture';
import Picture from '../Picture';

type PersonCardProps = {
  person: {
    id: string;
    title: string;
    url: string;
    jobTitles: string[];
    image: ImageData | null;
  };
};

type PersonCard = (props: PersonCardProps) => React.JSX.Element | null;

const PersonCard: PersonCard = ({ person }) => {
  const { title, url, jobTitles, image } = person;

  return (
    <div className="group">
      {image && (
        // Same aspect ratio for all screen sizes
        <Link href={url} className="mb-7 block overflow-hidden shadow-person-card">
          <Picture
            sources={image.sources}
            imageProps={{
              src: image.fallbackUrl,
              alt: image.alt,
              className:
                'aspect-square w-full object-cover transition-transform duration-700 lg:motion-safe:group-has-[a:hover]:scale-105',
              width: image.width,
              height: image.height,
            }}
          />
        </Link>
      )}
      <Link href={url} className="mb-1 block">
        <h3 className="heading text-3xl">{title}</h3>
      </Link>
      {!!jobTitles.length && <p className="mb-4 text-base">{jobTitles.join(', ')}</p>}
      <Link
        href={url}
        className="mt-4 flex items-center overflow-hidden font-bold text-brabners-copper-600"
        aria-label="Read more"
      >
        <svg
          width="56"
          height="14"
          viewBox="0 0 56 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          role="presentation"
          className="-translate-x-1/3 transition-transform duration-300 motion-safe:group-has-[a:hover]:translate-x-0"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M51.5391 6.28345L47.1387 2.00002L48.5338 0.566895L55.4336 7.28345L48.5338 14L47.1387 12.5669L51.5391 8.28345H0V6.28345H51.5391Z"
            fill="currentColor"
          />
        </svg>
      </Link>
    </div>
  );
};

export default PersonCard;
