import type { FieldTypeProps } from '@/types/forms';
import type { FormField_Hidden } from '@/types/fragments/form';
import useFieldAttrs from '../fieldAttrs';

export default function Hidden(props: FieldTypeProps<FormField_Hidden>) {
  const { field } = props;
  // ============================== Hooks
  const [, , getAttrs] = useFieldAttrs<FormField_Hidden>(props);

  return <input {...getAttrs()} type="hidden" defaultValue={field.defaultValue || ''} />;
}
