import resolveConfig from 'tailwindcss/resolveConfig';
import baseTailwindConfig from '@/../tailwind.config';

export const tailwindScreens = resolveConfig(baseTailwindConfig).theme.screens as Record<
  'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl',
  string
>;

export const tailwindScreensUnitless = Object.fromEntries(
  Object.entries(tailwindScreens).map(([key, value]) => [key, parseInt(value, 10)]),
) as Record<'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl', number>;
