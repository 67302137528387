import type { FieldTypeProps } from '@/types/forms';
import type { FormField_Agree } from '@/types/fragments/form';
import useFieldAttrs from '../fieldAttrs';
import FieldWrapper from './FieldWrapper';

export default function Agree(props: FieldTypeProps<FormField_Agree>) {
  const { field } = props;

  // ============================== Hooks
  const [getId, , getAttrs] = useFieldAttrs<FormField_Agree>(props);
  const { defaultValue, ...attrs } = getAttrs(); // Checkbox uses value not defaultValue

  return (
    <FieldWrapper field={field}>
      <div className="mt-2 space-y-2">
        <div className="flex items-center">
          <input
            defaultChecked={field.defaultState}
            value="1"
            type="checkbox"
            className="bg-green h-4 w-4 rounded border-gray-300 text-brabners-copper-300"
            {...attrs}
          />

          <label htmlFor={getId()} className="ml-3 block text-sm">
            {field.descriptionHtml}
          </label>
        </div>
      </div>
    </FieldWrapper>
  );
}
