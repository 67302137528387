'use client';

import type AnimeJS from 'animejs';
import { useEffect, useRef, useState } from 'react';
import useMedia from '@/lib/hooks/useMedia';

type AnimatedPatternProps = AnimeJS.AnimeParams & {
  children: React.ReactNode;
};

let animeJsPromise: Promise<{ default: typeof AnimeJS }> | null = null;

const defaultOptions: AnimeJS.AnimeParams = {
  easing: 'easeInOutCubic',
  duration: 3000,
  delay: 1000,
  // delay(el, i) {
  //   return i * 250;
  // },
  direction: 'normal',
  loop: false,
};

export default function AnimatedPattern({ children, ...options }: AnimatedPatternProps) {
  const isMotionSafe = useMedia('(prefers-reduced-motion: no-preference)');
  const [isInitialised, setIsInitialised] = useState(false);

  useEffect(() => {
    if (isInitialised || !isMotionSafe) return;

    const initAnimeJs = async () => {
      // Only import once globally for all component instances
      if (!animeJsPromise) {
        animeJsPromise = import('animejs/lib/anime.es');
      }

      const { default: anime } = await animeJsPromise;
      if (isInitialised) return; // Check again
      anime({
        targets: ref.current!.querySelectorAll('path'),
        strokeDashoffset: [anime.setDashoffset, 0],
        ...defaultOptions,
        ...options,
      });
      setIsInitialised(true);
    };

    initAnimeJs();
  }, [isInitialised, isMotionSafe, options]);
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <div ref={ref} className={isInitialised ? undefined : 'motion-safe:hidden'}>
      {children}
    </div>
  );
}
