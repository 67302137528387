import type { FieldTypeProps } from '@/types/forms';
import type { FormField_Html } from '@/types/fragments/form';
import FieldWrapper from './FieldWrapper';
import SanitizeHtml from '@/components/utils/SantizeHtml';

export default function Html(props: FieldTypeProps<FormField_Html>) {
  const { field } = props;
  // ============================== Hooks

  return (
    <FieldWrapper field={field}>
      <SanitizeHtml innerHtml={field.htmlContent} />
    </FieldWrapper>
  );
}
