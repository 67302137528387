'use client';

import type { ImageData } from '@/components/Picture';
import { Hits, type HitsProps } from 'react-instantsearch';
import PersonCard from '@/components/cards/PersonCard';

type PersonHit = {
  objectID: string;
  title: string;
  url: string;
  services: string[];
  sectors: string[];
  locations: string[];
  jobTitles: string[];
  image: ImageData;
};

export default function HitsWrapper(props: HitsProps<PersonHit>) {
  return <Hits hitComponent={PersonCardWrapper} {...props} />;
}

type PersonCardWrapperProps = { hit: PersonHit };

function PersonCardWrapper({
  hit: { objectID, services, sectors, locations, ...hit },
}: PersonCardWrapperProps) {
  return (
    <PersonCard
      person={{
        id: objectID,
        ...hit,
      }}
    />
  );
}
