import {
  RangeInput,
  RefinementList,
  useInstantSearch,
  useRange,
  useRefinementList,
} from 'react-instantsearch';
import {
  useClick,
  useFloating,
  useInteractions,
  autoPlacement,
  useTransitionStyles,
  useDismiss,
} from '@floating-ui/react';
import { useState, useRef, useEffect } from 'react';

type RefinementPanelProps = {
  label: string;
  attribute: string;
};

export function RefinementPanel({ label, attribute }: RefinementPanelProps) {
  const id = `dropdown-${attribute}`;
  // tooltip vars
  const [isOpen, setIsOpen] = useState(false);
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      autoPlacement({
        crossAxis: false,
        allowedPlacements: ['top-start', 'bottom-start'],
      }),
    ],
  });
  const click = useClick(context);
  const { styles } = useTransitionStyles(context);
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

  // algolia state
  const { uiState, setUiState } = useInstantSearch();
  const uiStateRef = useRef(uiState);
  // Keep up to date uiState in a reference
  useEffect(() => {
    uiStateRef.current = uiState;
  }, [uiState]);

  // Apply latest uiState to InstantSearch as the component is unmounted
  useEffect(() => {
    return () => {
      setTimeout(() => setUiState(uiStateRef.current));
    };
  }, [setUiState]);

  return (
    <div className="relative inline-flex">
      <button
        ref={refs.setReference}
        id={id}
        {...getReferenceProps()}
        className="relative flex w-full items-center justify-between border-b border-solid border-brabners-navy-200 py-3 text-lg lg:text-xl"
      >
        {label}
        <svg
          className="text-brabners-copper-300 motion-safe:transition-transform motion-safe:duration-300"
          viewBox="0 0 16 16"
          height="16"
          width="16"
          fill="none"
          role="presentation"
        >
          <path
            d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      </button>

      {isOpen && (
        <div
          className="z-[1] min-w-[15rem] border border-solid border-brabners-navy-200 bg-brabners-navy-200 p-4 shadow-glow transition-[opacity,margin] duration-300 motion-safe:mt-2"
          ref={refs.setFloating}
          aria-labelledby={id}
          style={{
            ...floatingStyles,
            ...styles,
          }}
          {...getFloatingProps()}
        >
          <RefinementList
            attribute={attribute}
            limit={100}
            sortBy={['name:asc']}
            classNames={{
              count: 'hidden',
              checkbox: 'mr-3',
            }}
          />
        </div>
      )}
    </div>
  );
}

/**
 * https://www.algolia.com/doc/guides/building-search-ui/widgets/show-and-hide-widgets/react/#persist-the-state-on-unmount
 */
export function VirtualRefinementPanel({ attribute }: { attribute: string }) {
  useRefinementList({ attribute });

  return null;
}
