/* eslint-disable jsx-a11y/alt-text */

import type { SetRequired } from '@/types/utils';
// import ReactDOM from 'react-dom';

type SourceData = {
  format: string;
  srcset: string;
  minWidth: string | null;
};

export type ImageData = {
  width: number;
  height: number;
  fallbackUrl: string;
  alt: string;
  sources: SourceData[];
};

type PictureProps = React.ComponentPropsWithoutRef<'picture'> & {
  imageProps: SetRequired<React.ComponentPropsWithoutRef<'img'>, 'src' | 'alt'>;
  priority?: boolean;
  sources: SourceData[];
};

export default function Picture({ imageProps, priority, sources, ...pictureProps }: PictureProps) {
  return (
    <picture {...pictureProps}>
      {sources.map(({ format, srcset, minWidth }, sourceIndex) => {
        // if (priority) {
        //   // See https://github.com/facebook/react/pull/26940
        //   ReactDOM.preload(imageProps.src, {
        //     as: 'image',
        //     imageSrcSet: srcset,
        //     imageSizes: imageProps.sizes,
        //     crossOrigin: imageProps.crossOrigin,
        //     referrerPolicy: imageProps.referrerPolicy,
        //     fetchPriority: 'high',
        //     media: minWidth ? `(min-width: ${minWidth})` : undefined,
        //   });
        // }

        return (
          <source
            key={sourceIndex}
            type={`image/${format}`}
            media={minWidth ? `(min-width: ${minWidth})` : undefined}
            srcSet={srcset}
          />
        );
      })}

      <img
        fetchPriority={priority ? 'high' : 'auto'}
        loading={priority ? 'eager' : 'lazy'}
        {...imageProps}
      />
    </picture>
  );
}
