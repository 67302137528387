import Address from './Address';
import Agree from './Agree';
import Checkboxes from './Checkboxes';
import Date from './Date';
import Dropdown from './Dropdown';
import Email from './Email';
import Entries from './Entries';
import FileUpload from './FileUpload';
import Group from './Group';
import Heading from './Heading';
import Hidden from './Hidden';
import Html from './Html';
import MultiLineText from './MultiLineText';
import Name from './Name';
import Number from './Number';
import Password from './Password';
import Phone from './Phone';
import Radio from './Radio';
import Section from './Section';
import SingleLineText from './SingleLineText';

const components = {
  Address,
  Agree,
  Checkboxes,
  Date,
  Dropdown,
  Email,
  Entries,
  FileUpload,
  Heading,
  Hidden,
  Html,
  MultiLineText,
  Name,
  Number,
  Password,
  Phone,
  Radio,
  Section,
  SingleLineText,
};

const allInputs = {
  ...components,
  Group,
};

export { components as inputs, allInputs };
