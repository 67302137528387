'use client';

import type AnimeJS from 'animejs';
import { useEffect, useRef, useState } from 'react';
import useMedia from '@/lib/hooks/useMedia';

import type { FeatureAnimationProps } from '../FeatureHero';
import Picture from '@/components/Picture';
import { getImageSources } from '@/lib/craftImage';
import SoundWaveVertical from '@/components/icons/SoundWaveVertical.svg';

let animeJsPromise: Promise<{ default: typeof AnimeJS }> | null = null;

const ConstructionAnimation = ({
  fallbackImage,
  rawImageSources,
  className,
}: FeatureAnimationProps) => {
  const isMotionSafe = useMedia('(prefers-reduced-motion: no-preference)');
  const [isInitialised, setIsInitialised] = useState(false);

  useEffect(() => {
    if (isInitialised || !isMotionSafe) return;

    const initAnimeJs = async () => {
      // Only import once globally for all component instances
      if (!animeJsPromise) {
        animeJsPromise = import('animejs/lib/anime.es');
      }

      const { default: anime } = await animeJsPromise;
      if (isInitialised) return; // Check again

      const tl = anime.timeline({
        easing: 'easeOutSine',
        delay: 2000,
      });
      tl.add({
        targets: ref.current!.querySelectorAll('.anime-liftupRotate'),
        translateY: '-20%',
        rotate: 15.75,
        duration: 1500,
      }),
        tl.add(
          {
            targets: ref.current!.querySelectorAll('.anime-shadowLiftup'),
            scaleX: 0.6,
            scaleY: 0.8,
            duration: 1500,
          },
          0,
        ),
        // tl.add({
        //   targets: ref.current!.querySelectorAll('.anime-svg'),
        //   opacity: [0, 1],
        //   duration: 0.75,
        // }),
        tl.add(
          {
            targets: ref.current!.querySelectorAll('.anime-svg path'),
            strokeDashoffset: [anime.setDashoffset, 0],
            duration: 1500,
          },
          0,
        );
      setIsInitialised(true);
    };

    initAnimeJs();
  }, [isInitialised, isMotionSafe]);

  const ref = useRef<HTMLDivElement | null>(null);
  return (
    <div className="relative mt-10" ref={ref}>
      <SoundWaveVertical
        className={`anime-svg absolute left-0 top-0 -translate-x-6 -translate-y-[30%] lg:-translate-x-16 ${className}`}
      />
      <div className="anime-liftupRotate relative">
        <Picture
          className="relative left-0 top-4 z-10"
          sources={getImageSources('construction', rawImageSources)}
          priority={true}
          imageProps={{
            src: fallbackImage.url,
            alt: fallbackImage.alt || fallbackImage.title,
            // No fixed aspect ratio
            className: 'object-cover',
            loading: 'eager',
            decoding: 'async',
            width: fallbackImage.width,
            height: fallbackImage.height,
          }}
        />
      </div>
      <span className="anime-shadowLiftup absolute h-12 w-full scale-100 transform-gpu rounded-[35%] bg-black bg-opacity-50 mix-blend-multiply blur" />
    </div>
  );
};

export default ConstructionAnimation;
