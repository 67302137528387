import type { FieldTypeProps } from '@/types/forms';
import type { FormField, FormField_Group } from '@/types/fragments/form';
import { inputs } from './';
import FieldWrapper from './FieldWrapper';

type DisplayType = keyof typeof inputs;

export default function Group(props: FieldTypeProps<FormField_Group>) {
  const { field } = props;
  // ============================== Hooks

  return (
    <FieldWrapper field={field}>
      <fieldset className="relative mb-5 rounded-md border border-gray-200 bg-gray-100 p-4">
        {(field.fields as FormField[]).map((nestedField, index) => {
          const Component = inputs[nestedField.displayName as DisplayType];
          /*
            `field` resolves to `Field_A & Field_B`, which is `never`, instead of `Field_A | Field_B`
            like you'd expect. Possibly a case of "correspondance problem":
            https://discord.com/channels/508357248330760243/1206402189942198362/1206427113423376394
          */
          return <Component key={index} field={nestedField as never} namespace={[field.handle]} />;
        })}
      </fieldset>
    </FieldWrapper>
  );
}
