import type { AddPrefixCapitalized, StringKey } from '@/types/utils';
import type {
  FieldWithSubfields,
  NarrowedKey,
  SubField,
  SubFieldEntry,
  SubFieldKey,
  SubFieldType,
} from '@/types/forms';
import camelCase from 'lodash/camelCase';

export function attributesTable(table: Array<{ label: string; value: string }>) {
  return Object.fromEntries(table.map((row) => [row.label, row.value]));
}

function capitalise<S extends string>(s: S) {
  return (s.charAt(0).toUpperCase() + s.slice(1)) as Capitalize<S>;
}

/**
 * eg.
 * ```ts
 * getSubFields(
 *   {
 *     // First name fields
 *     firstNameLabel: 'First Name',
 *     firstNameEnabled: true,
 *     firstNameRequired: false,
 *
 *     // Middle name fields
 *     middleNameLabel: 'Middle Name',
 *     middleNameEnabled: false,
 *     middleNameRequired: false,
 *
 *     // Last name fields
 *     lastNameLabel: 'Last Name',
 *     lastNameEnabled: true,
 *     lastNameRequired: true,
 *
 *     // Top-level fields
 *     __typename: 'Field_Name',
 *     useMultipleFields: boolean,
 *     id: string,
 *     name: string,
 *     handle: string,
 *     // ...
 *   },
 *  ['firstName', 'middleName', 'lastName'],
 * )
 * ```
 *
 * gives
 *
 * ```ts
 * [
 *   {
 *     __typename: 'Field_FirstName',
 *     handle: 'firstName',
 *     label: 'First Name',
 *     required: false,
 *   },
 *   {
 *     __typename: 'Field_LastName',
 *     handle: 'lastName',
 *     label: 'Last Name',
 *     required: true,
 *   },
 * ]
 * ```
 */
export function getSubFields<F extends FieldWithSubfields>(
  field: F,
  subFieldTypes: SubFieldType<F>[],
): SubField<F>[] {
  /**
   * Remove disabled subfields field names, eg. ['middleName', 'lastName'] => ['lastName']
   */
  const enabledSubFieldTypes = subFieldTypes.filter((subFieldType) => {
    type ThisSubFieldType = typeof subFieldType;
    const enabledField: AddPrefixCapitalized<typeof subFieldType, 'enabled'> =
      `${subFieldType}Enabled`;
    // Think extends keyof F check is necessary because F is extensible, not 100% sure. It should
    // always be Field_Address | Field_Name in reality though.
    return !!field[enabledField as ThisSubFieldType extends keyof F ? ThisSubFieldType : never];
  });

  /**
   * eg. ['firstName', 'lastName'] => [
   *   ['firstName', { label: 'First Name', required: true }],
   *   ['lastName', { label: 'Last Name', required: true }],
   * ]
   */
  const subFields = enabledSubFieldTypes.map((subFieldType) => {
    type ThisSubFieldType = typeof subFieldType;

    /**
     * All keys for this field type, eg. ['firstNameLabel', 'firstNameRequired',
     * 'lastNameLabel', 'lastNameRequired', 'handle', 'required', ...].
     *
     * `Object.keys()` doesn't preserve the type of the keys, so we need to cast it.
     */
    const keys = Object.keys(field) as StringKey<F>[];

    /**
     * The keys for this specific subfield type, eg. 'enabled' | 'required' | 'placeholder'...
     */
    type ThisSubFieldKey = SubFieldKey<F, ThisSubFieldType>;

    /**
     * Only the keys prefixed with this subfield type, eg. ['firstNameLabel', 'firstNameRequired',
     * 'handle'] => ['firstNameLabel', 'firstNameRequired']
     */
    const narrowedKeys = keys.filter((key) => key.startsWith(subFieldType)) as NarrowedKey<
      F,
      ThisSubFieldType
    >[];

    const subFieldTypeNameEntry = [
      '__typename',
      `Field_${capitalise(subFieldType)}`,
    ] as SubFieldEntry<F, ThisSubFieldType, '__typename'>;
    const subfieldLabelPositionEntry = [
      'labelPosition',
      field.subfieldLabelPosition,
    ] as SubFieldEntry<F, ThisSubFieldType, 'labelPosition'>;
    const subFieldHandleEntry = ['handle', subFieldType] as SubFieldEntry<
      F,
      ThisSubFieldType,
      'handle'
    >;

    /**
     * eg. ['firstNameLabel', 'firstNameRequired'] => [['label', 'First Name'], ['required', true]]
     */
    const subFieldEntries = [
      subFieldTypeNameEntry,
      subFieldHandleEntry,
      subfieldLabelPositionEntry,
      ...narrowedKeys.map((fieldKey) => {
        /**
         * Remove the subfield type from the field key, eg. 'firstNameRequired' => 'required'
         */
        const unprefixedFieldKey = camelCase(fieldKey.replace(subFieldType, '')) as ThisSubFieldKey;

        type ThisSubFieldEntry = SubFieldEntry<F, ThisSubFieldType, ThisSubFieldKey>;
        const subFieldEntry: ThisSubFieldEntry = [
          unprefixedFieldKey,
          field[fieldKey] as ThisSubFieldEntry[1],
        ];

        return subFieldEntry;
      }),
    ];

    /**
     * eg. [['label', 'First Name'], ['required', true]] => { label: 'First Name', required: true }
     *
     * `Object.fromEntries()` doesn't preserve the type of the keys, so we need to cast it.
     */
    const subField = Object.fromEntries(subFieldEntries) as SubField<F, ThisSubFieldType>;

    return subField;
  });

  return subFields;
}

export async function fileToDataUrl(file: File) {
  // Read the file data as a Buffer
  const fileData = await file.arrayBuffer();
  const buffer = Buffer.from(fileData);
  // Convert the buffer to Base64
  const base64Data = buffer.toString('base64');

  return `data:${file.type};base64,${base64Data}`;
}

// export function fileToDataUrl(file: File) {
//   return new Promise<string>((resolve, reject) => {
//     const reader = new FileReader();
//     reader.onloadend = () => resolve(reader.result as string);
//     reader.onerror = reject;
//     reader.readAsDataURL(file);
//   });
// }
