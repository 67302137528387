'use client';

import { useEffect, useState } from 'react';

export default function useMedia(query: string) {
  // Initialise `media` on first client render
  const [media, setMedia] = useState<MediaQueryList | null>(null);
  useEffect(() => {
    setMedia(window.matchMedia(query));
  }, [query]);

  // Initialise `matches` on first client render
  const [matches, setMatches] = useState<boolean | null>(null);
  useEffect(() => {
    if (media) {
      setMatches(media.matches);
    }
  }, [media]);

  // Add listener to `media` to update `matches`
  useEffect(() => {
    if (!media) return;
    const listener = () => setMatches(media.matches);
    media.addEventListener
      ? media.addEventListener('change', listener)
      : media.addListener(listener);

    return () =>
      media.removeEventListener
        ? media.removeEventListener('change', listener)
        : media.removeListener(listener);
  }, [matches, media]);

  return matches;
}
