import type { FieldTypeProps } from '@/types/forms';
import type { FormField_Dropdown } from '@/types/fragments/form';
import useFieldAttrs from '../fieldAttrs';
import FieldWrapper from './FieldWrapper';

export default function Dropdown(props: FieldTypeProps<FormField_Dropdown>) {
  const { field } = props;
  // ============================== Hooks
  const [, , getAttrs] = useFieldAttrs<FormField_Dropdown>(props);
  const defaultOption = field.options.find((option) => option.isDefault);
  const { defaultValue, ...attrs } = getAttrs(); // Using defaultOption.value for defaultValue instead of field.value

  return (
    <FieldWrapper field={field}>
      <select
        multiple={field.multi}
        defaultValue={defaultOption?.value}
        {...attrs}
        className="block w-full appearance-none rounded-[1.25rem] bg-brabners-navy-200 bg-select bg-no-repeat py-2 pl-5 pr-8 text-sm"
      >
        {field.options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </FieldWrapper>
  );
}
