'use client';

import type {
  Block_SummaryCards,
  Block_SummaryCards_Entry as Entry,
} from '@/types/fragments/mainNeo';
import { useEffect, useState } from 'react';
import useMedia from '@/lib/hooks/useMedia';
import { tailwindScreens } from '@/lib/tailwindConfig';
import getColourScheme from '@/lib/theme';
import { getSpacingClasses } from '@/lib/utils';
import SwiperCarousel from '@/components/Carousel';
import SummaryCard from '@/components/cards/SummaryCard';

type SummaryCards = (props: Block_SummaryCards) => React.JSX.Element | null;

const SummaryCards: SummaryCards = ({ entries, anchor, spacing }) => {
  const isDesktop = useMedia(`(min-width: ${tailwindScreens.md})`);
  const [isClient, setIsClient] = useState(false);
  useEffect(() => setIsClient(true), []);

  if (!entries.length) return null;
  const spacingClasses = getSpacingClasses(spacing);
  // Render mobile version by default on the server
  const useMobile = !isClient || !isDesktop;
  const useCarousel = entries.length > 1 && useMobile;
  const theme = getColourScheme('blue');

  return (
    <section
      id={anchor || undefined}
      className={`${theme.bg} ${theme.text} ${theme.heading} ${spacingClasses}`}
    >
      {/*
        Google shouldn't penalise us for this as "text that's only accessible to screen readers and is
        intended to improve the experience for those using screen readers":
        https://developers.google.com/search/docs/essentials/spam-policies?hl=en&visit_id=638512879682775441-3653845950&rd=1#hidden-text-and-links
      */}
      <h2 className="sr-only">Newsreel</h2>
      <div className="container max-w-heading-cards px-0 md:px-10">
        {useCarousel ? (
          <SwiperCarousel
            class={`${theme.swiperTheme} ${theme.swiperPaginationInactive} [--swiper-pagination-bottom:1.25rem]`}
          >
            {(entries as Entry[]).map((entry) => (
              <swiper-slide key={entry.id} suppressHydrationWarning>
                <SummaryCard {...entry} className="px-10 pb-16 pt-9 xl:px-0" />
              </swiper-slide>
            ))}
          </SwiperCarousel>
        ) : (
          <div className="grid gap-28 md:grid-cols-3">
            {(entries as Entry[]).map((entry) => (
              <SummaryCard key={entry.id} {...entry} className="max-w-md" />
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default SummaryCards;
