import type { FormField as FormFieldQueryData, SupportedFieldType } from '@/types/fragments/form';
import type { RemovePrefix } from '@/types/utils';
import { allInputs } from './inputs/index';
import { attributesTable } from './utils';

/*
Unsupported field types:
- Calculations
- Categories
- Payment
- Recipients
- Repeater
- Signature
- Summary
- Table
- Tags
- Users
*/

type FieldProps = {
  field: FormFieldQueryData;
};

export default function FormField({ field }: FieldProps) {
  // ============================== Derived state
  const canInclude = field.visibility !== 'disabled';
  if (!canInclude) return null;

  const isVisible = field.visibility !== 'hidden' && field.displayName !== 'Hidden';

  const attrs = {
    ...attributesTable(field.containerAttributes || []), // May be null
  };

  const fieldType = field.__typename as SupportedFieldType;
  const componentName = typenameToComponentName(fieldType);
  const Component = allInputs[componentName];

  return (
    <div
      className={`mb-5 w-full flex-1 ${isVisible ? '' : 'hidden'} ${field.cssClasses || ''}`}
      {...attrs}
    >
      {/*
        `field` resolves to `Field_A & Field_B`, which is `never`, instead of `Field_A | Field_B`
        like you'd expect. Possibly a case of "correspondance problem":
        https://discord.com/channels/508357248330760243/1206402189942198362/1206427113423376394
      */}
      <Component field={field as never} />
    </div>
  );
}

export function typenameToComponentName<T extends string>(type: T) {
  return type.replace('Field_', '') as RemovePrefix<'Field_', T>;
}
