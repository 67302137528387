import type { FieldTypeProps } from '@/types/forms';
import type {
  FormField_Date,
  FormField_Email,
  FormField_FileUpload,
  FormField_Name,
  FormField_Number,
  FormField_Password,
  FormField_Phone,
  FormField_SingleLineText,
} from '@/types/fragments/form';
import useFieldAttrs from '../fieldAttrs';
import FieldWrapper from './FieldWrapper';

type FormField_Input =
  | FormField_Date
  | FormField_Email
  | FormField_FileUpload
  | FormField_Name
  | FormField_Number
  | FormField_Password
  | FormField_Phone
  | FormField_SingleLineText;

type InputFieldProps = React.ComponentPropsWithoutRef<'input'> & FieldTypeProps<FormField_Input>;

export default function Input(props: InputFieldProps) {
  const { field, type = 'text', ...otherProps } = props;
  // ============================== Hooks
  const [, , getAttrs] = useFieldAttrs<FormField_Input>(props);
  const { defaultValue, ...attrs } = getAttrs();

  // Maybe this shouldn't include FieldWrapper?
  return (
    <FieldWrapper field={field}>
      <input
        {...attrs}
        type={type}
        className="block w-full rounded-[1.25rem] bg-brabners-navy-200 px-5 py-2 text-sm"
        {...otherProps}
        // Can't use both value and defaultValue
        defaultValue={'value' in otherProps ? undefined : defaultValue}
      />
    </FieldWrapper>
  );
}
