import type { FieldTypeProps } from '@/types/forms';
import type { FormField_Section } from '@/types/fragments/form';

type BorderStyle = 'solid' | 'dashed';

export default function Section({ field }: FieldTypeProps<FormField_Section>) {
  const style: React.CSSProperties = {
    borderTopStyle: field.borderStyle ? (field.borderStyle as BorderStyle) : undefined,
    borderTopWidth: field.borderWidth ? `${field.borderWidth}px` : undefined,
    borderTopColor: field.borderColor,
  };

  return <hr style={style} />;
}
