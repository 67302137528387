import Link from 'next/link';
import { useHits, useStats } from 'react-instantsearch';

type HeaderSearchHit = {
  objectID: string;
  title: string;
  url: string;
};

export default function HeaderSearchStats() {
  const { nbHits, query } = useStats();

  if (nbHits > 0 && query.length) {
    return (
      <section className="c-header-search__results max-h-80 overflow-y-scroll px-3 py-2">
        <Hits />
      </section>
    );
  }

  if (nbHits === 0 && query.length > 0) {
    const shortened = query.length > 20 ? `${query.slice(0, 20)}...` : query;
    return (
      <section className="px-5 py-3">
        <p className="text-sm">No results have been found for {shortened}.</p>
      </section>
    );
  }

  return null;
}

function Hits() {
  const { items } = useHits<HeaderSearchHit>();
  return (
    <ul className="flex flex-col justify-start">
      {items.map((hit) => (
        <li key={hit.objectID}>
          <Link href={hit.url} className="block py-2 text-sm font-normal hover:underline">
            {hit.title}
          </Link>
        </li>
      ))}
    </ul>
  );
}
