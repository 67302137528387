'use client';

import type { FieldTypeProps } from '@/types/forms';
import type { FormField_SingleLineText } from '@/types/fragments/form';
import Input from './Input';

export default function SingleLineText(props: FieldTypeProps<FormField_SingleLineText>) {
  const { field } = props;
  const maxlength = field.limit && field.maxType === 'characters' ? field.max : undefined;

  return <Input field={field} maxLength={maxlength} type="text" />;
}
