type BreakpointKey = '4xl' | '3xl' | '2xl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs' | 'xxs';
type Breakpoints = {
  [Key in BreakpointKey]: Key extends 'xxs' ? null : `${number}px`;
};
type Format = 'avif' | 'webp' | 'jpeg';
type ImageFieldNameParts = ['img', string, Format, BreakpointKey];
type ImageFieldName = `img_${string}_${Format}_${BreakpointKey}`;

type SrcSetUrlRecord = {
  [key in ImageFieldName]?: {
    srcset: string;
  }[];
};

export type SourceData = {
  minWidth: string | null;
  format: Format;
  srcset: string;
};

const shiftedBreakpoints: Breakpoints = {
  '4xl': '1920px',
  '3xl': '1536px',
  '2xl': '1280px',
  xl: '1024px',
  lg: '768px',
  md: '640px',
  sm: '425px',
  xs: '320px',
  xxs: null,
};

/**
 * Extracts image sources from a CraftCMS GQL query response for the given image handle. This should
 * only be called if the image is set, which you can check using the fallback image. eg.
 *
 * ```tsx
 * fallbackImage && (
 *   <Picture
 *     sources={getImageSources('image', rawImageSources)}
 *     ...
 *   />
 * )
 *
 * @param imageHandle Eg. for the field `img_myImage_avif_xs`, the handle is `myImage`
 * @param rawImageSources An object where each field is an array with a single entry, similar to
 * `entry.image.limit(1).all` in Twig. eg.
 * ```ts
 * {
 *   img_myImage_avif_xs: [{ srcset: '...' }],
 *   img_myImage_avif_sm: [{ srcset: '...' }],
 *   img_myImage_webp_sm: [{ srcset: '...' }],
 *   img_myMobileImage_avif_xs: [{ srcset: '...' }],
 * }
 * @returns
 */
export function getImageSources<D extends SrcSetUrlRecord>(
  imageHandle: string | string[],
  rawImageSources: D,
) {
  const imageHandles = Array.isArray(imageHandle) ? imageHandle : [imageHandle];
  const imageUrlEntries = Object.entries(rawImageSources).filter(([key]) =>
    key.startsWith('img_'),
  ) as [keyof SrcSetUrlRecord, { srcset: string }[]][];

  const sources: SourceData[] = (
    imageUrlEntries
      .map(([key, srcSetDatas]) => {
        /**
         * `srcSetDatas` is an array of objects with one entry, similar to entry.image.limit(1).all
         * in Twig. If `fallbackImage` is set then there'll always be one entry, so we can safely
         * access the first one.
         */
        const srcSetData = srcSetDatas[0];

        const [, handle, format, breakpoint] = key.split('_') as ImageFieldNameParts;

        if (!imageHandles.includes(handle)) {
          return null;
        }

        return {
          format,
          minWidth: shiftedBreakpoints[breakpoint],
          srcset: srcSetData.srcset,
        };
      })
      .filter(Boolean) as SourceData[]
  ).sort((a, b) => {
    // Sort by minWidth descending (the browser will use the first matching source rather than the
    // last)
    const aMinWidth = a.minWidth ? parseInt(a.minWidth) : 0;
    const bMinWidth = b.minWidth ? parseInt(b.minWidth) : 0;
    const res = bMinWidth - aMinWidth;

    // Order by AVIF first, then WEBP
    if (res === 0) {
      return a.format === 'avif' ? -1 : 1;
    }

    return res;
  });
  return sources;
}

type RawImageSources = Record<
  string,
  {
    srcset: string;
  }[]
>;

/**
 * Combines source data from `image` and `mobileImage` so that they can be used in a single
 * `<picture>` element.
 */
export function getCombinedImageSources(rawImageSources: RawImageSources, hasMobileImage: boolean) {
  const desktopSources = getImageSources('image', rawImageSources);
  const cutoff = 768;

  if (!hasMobileImage) {
    return desktopSources;
  }

  /**
   * Remove mobile screen sizes from desktop sources
   */
  const filteredDesktopSources = desktopSources.filter(
    (source) => source.minWidth && parseInt(source.minWidth, 10) >= cutoff,
  );

  const mobileSources = getImageSources('mobile', rawImageSources);

  /**
   * Remove desktop screen sizes from mobile sources
   */
  const filteredMobileSources = mobileSources.filter(
    (source) => source.minWidth && parseInt(source.minWidth, 10) < cutoff,
  );

  return [...filteredDesktopSources, ...filteredMobileSources];
}
