import type { InstantSearchProps } from 'react-instantsearch';
import algoliasearch from 'algoliasearch/lite';
import { history as historyRouter } from 'instantsearch.js/es/lib/routers';

type RefinementList = { [key: string]: string[] };

type UiState = {
  [indexName: string]: {
    refinementList?: RefinementList;
    page?: number;
    query?: string;
  };
};
type UiRoute = Record<string, string /* | string[] | number */>;
type Routing = Exclude<InstantSearchProps<UiState, UiRoute>['routing'], undefined | boolean>;

export const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID as string,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY as string,
);

const router = historyRouter<UiRoute>();

export function getPeopleRouting(indexName: string): Routing {
  return {
    router,
    stateMapping: {
      stateToRoute(uiState: UiState): UiRoute {
        const indexUiState = uiState[indexName];
        const route: UiRoute = {};
        const { refinementList, page, query } = indexUiState;
        if (refinementList) {
          Object.keys(refinementList).forEach((key) => {
            if (refinementList[key]) {
              route[key] = refinementList[key].join('~');
            }
          });
        }
        if (query) {
          route.q = query;
        }
        if (page && page > 1) {
          route.p = page.toString();
        }
        return route;
      },
      routeToState(routeState: UiRoute): UiState {
        return {
          [indexName]: {
            query: routeState.q,
            refinementList: {
              ...(routeState.services ? { services: routeState.services.split('~') } : undefined),
              ...(routeState.subServices
                ? { subServices: routeState.subServices.split('~') }
                : undefined),
              ...(routeState.sectors ? { sectors: routeState.sectors.split('~') } : undefined),
              ...(routeState.subSectors
                ? { subSectors: routeState.subSectors.split('~') }
                : undefined),
              ...(routeState.locations
                ? { locations: routeState.locations.split('~') }
                : undefined),
            },
            page: Number(routeState.p),
          },
        };
      },
    },
  };
}
