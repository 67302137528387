'use client';

import type { Block_CookieDeclaration } from '@/types/fragments/mainNeo';
import { getSpacingClasses } from '@/lib/utils';
import { useEffect, useRef } from 'react';

export default function CookieDeclaration({ anchor, spacing }: Block_CookieDeclaration) {
  const spacingClasses = getSpacingClasses(spacing);
  const legalContainerRef = useRef<HTMLDivElement | null>(null);

  // This solution requires the script to run after the main cookiebot cmp script has loaded, which
  // could cause race conditions. Will revisit this is it proves to be a problem.
  // https://github.com/yeutech-lab/react-cookiebot/issues/11#issuecomment-1965524202
  useEffect(() => {
    const container = legalContainerRef.current;
    if (!container) return;
    const script = document.createElement('script');

    script.id = 'CookieDeclaration';
    script.src = `https://consent.cookiebot.com/${process.env.NEXT_PUBLIC_COOKIEBOT_ID}/cd.js`;
    script.type = 'text/javascript';
    script.async = true;

    container.appendChild(script);

    return () => {
      container.removeChild(script);
    };
  }, [legalContainerRef]);

  return (
    <section
      ref={legalContainerRef}
      id={anchor || undefined}
      className={`rich-text container ${spacingClasses}`}
      aria-label="Cookie declaration"
    />
  );
}
