import type { FieldTypeProps } from '@/types/forms';
import type { FormField_MultiLineText } from '@/types/fragments/form';
import useFieldAttrs from '../fieldAttrs';
import FieldWrapper from './FieldWrapper';

export default function MultiLineText(props: FieldTypeProps<FormField_MultiLineText>) {
  const { field } = props;
  // ============================== Hooks
  const [, , getAttrs] = useFieldAttrs<FormField_MultiLineText>(props);

  return (
    <FieldWrapper field={field}>
      <textarea
        {...getAttrs()}
        rows={3}
        className="block w-full rounded-[1.25rem] bg-brabners-navy-200 px-5 py-2 text-sm"
      />
    </FieldWrapper>
  );
}
