import SearchIcon from '@/components/icons/SearchIcon.svg';

type HeaderSearchButtonProps = {
  className?: string;
  setIsEnabled: (isEnabled: boolean) => void;
};

export default function HeaderSearchButton({ className, setIsEnabled }: HeaderSearchButtonProps) {
  return (
    <button
      className={className}
      onClick={() => {
        setIsEnabled(true);
      }}
    >
      {/* Hardcode the role to img pending a better solution - https://github.com/gregberge/svgr/issues/961 */}
      <SearchIcon role="img" />
    </button>
  );
}
