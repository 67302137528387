import type { FieldTypeProps } from '@/types/forms';
import type { FormField_Name } from '@/types/fragments/form';
import useFieldAttrs from '../fieldAttrs';
import { getSubFields } from '../utils';
import FieldInstructions from './partials/FieldInstructions';
import FieldLabel from './partials/FieldLabel';
import Input from './Input';

const autocomplete = {
  Field_Prefix: 'honorific-prefix',
  Field_FirstName: 'given-name',
  Field_MiddleName: 'additional-name',
  Field_LastName: 'family-name',
};

export default function Name(props: FieldTypeProps<FormField_Name>) {
  const { field } = props;
  const subFields = getSubFields(field, ['prefix', 'firstName', 'middleName', 'lastName']);

  // ============================== Hooks
  const [getId, getName, , getSubFieldAttrs] = useFieldAttrs<FormField_Name>(props);

  return field.useMultipleFields ? (
    <fieldset className="-mb-5">
      {/* Statically hidden until subfieldLabelPosition is added to gql schema - https://github.com/verbb/formie/issues/1797 */}
      {/* <legend className="mb-2 block text-sm">{field.name}</legend> */}

      <FieldInstructions field={field} position="above-input" />

      <div className="-mx-2 flex flex-col flex-wrap">
        {subFields.map((subField) => {
          const { __typename, handle } = subField;
          const id = getId([handle]);
          const useSelect = __typename === 'Field_Prefix';
          return (
            <div key={__typename} className="mb-5 flex-1 px-2">
              <FieldLabel<FormField_Name>
                id={id}
                field={field}
                subField={subField}
                position="above-input"
              />

              {useSelect ? (
                <select
                  id={id}
                  name={getName([handle])}
                  autoComplete={autocomplete[__typename]}
                  className="block w-full appearance-none rounded-[1.25rem] bg-brabners-navy-200 bg-select bg-no-repeat py-2 pl-5 pr-8 text-sm"
                >
                  {subField.options.map((option, j) => (
                    <option key={j} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  {...getSubFieldAttrs!(subField)}
                  autoComplete={autocomplete[__typename]}
                  type="text"
                  className="block w-full rounded-[1.25rem] bg-brabners-navy-200 px-5 py-2 text-sm"
                />
              )}

              <FieldLabel<FormField_Name>
                id={id}
                field={field}
                subField={subField}
                position="below-input"
              />
            </div>
          );
        })}
      </div>

      <FieldInstructions field={field} position="below-input" />
    </fieldset>
  ) : (
    <Input field={field} type="text" />
  );
}
