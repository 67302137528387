import sanitizeHtml from 'sanitize-html';

type IntrinsicElement = keyof JSX.IntrinsicElements;

type SanitizeHtmlProps<T extends IntrinsicElement> = {
  as?: T;
  innerHtml: string;
} & Omit<JSX.IntrinsicElements[T], 'dangerouslySetInnerHTML'>;

// https://stackoverflow.com/a/73710646

export default function SanitizeHtml<T extends IntrinsicElement = 'div'>({
  as: elementType = 'div' as T,
  innerHtml,
  ...rest
}: SanitizeHtmlProps<T>) {
  const Component = elementType as React.ElementType;
  return (
    <Component
      {...rest}
      dangerouslySetInnerHTML={{
        __html: sanitizeHtml(innerHtml, {
          allowedAttributes: {
            // Allow id and class for compatibility with ckeditor classes
            a: ['href', 'name', 'target', 'id', 'class', 'rel'],
          },
        }),
      }}
    />
  );
}
