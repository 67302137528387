'use client';

import { Fragment } from 'react';
import { useCurrentRefinements, type CurrentRefinementsProps } from 'react-instantsearch';

export default function CurrentRefinements(props: CurrentRefinementsProps) {
  const { items, canRefine, refine } = useCurrentRefinements(props);
  if (!canRefine) return null;

  return (
    <ul className="mb-8 flex flex-wrap justify-evenly gap-5 xs:mb-0 xs:justify-normal">
      {items.map((item) => (
        <Fragment key={item.attribute}>
          {item.refinements.map((refinement) => (
            <Refinement
              key={[
                refinement.attribute,
                refinement.type,
                refinement.value,
                refinement.operator,
              ].join(':')}
              refinement={refinement}
              refine={refine}
            />
          ))}
        </Fragment>
      ))}
    </ul>
  );
}

type CurrentRefinements = ReturnType<typeof useCurrentRefinements>;

type RefinementProps = {
  refine: CurrentRefinements['refine'];
  refinement: CurrentRefinements['items'][number]['refinements'][number];
};

function Refinement({ refinement, refine }: RefinementProps) {
  return (
    <li
      // Taking 5px padding from the right to give it to the remove icon
      className="flex items-center rounded-full bg-brabners-navy-200 px-8 py-2.5 pr-[1.6875rem] text-base transition-colors hover:bg-brabners-navy-100"
    >
      {refinement.label}
      <button
        className="ml-1.25 px-1.25"
        aria-label="Remove refinement"
        onClick={(event) => {
          if (isModifierClick(event)) {
            return;
          }

          refine(refinement);
        }}
      >
        <svg
          className="text-brabners-copper-300 transition-colors hover:text-white"
          height="14"
          width="14"
          viewBox="0 0 13 13"
          role="presentation"
        >
          <line
            x1="0.6"
            y1="0.6"
            x2="12.4"
            y2="12.4"
            stroke="currentColor"
            strokeWidth="1"
            strokeLinecap="round"
          />
          <line
            x1="0.6"
            y1="12.4"
            x2="12.4"
            y2="0.6"
            stroke="currentColor"
            strokeWidth="1"
            strokeLinecap="round"
          />
        </svg>
      </button>
    </li>
  );
}

function isModifierClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
  const isMiddleClick = event.button === 1;

  return Boolean(isMiddleClick || event.altKey || event.ctrlKey || event.metaKey || event.shiftKey);
}
